import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import './ChangePassword.css';
import Popup from '../../popupbox/Popup';
import useAxiosInstance from '../../../configs/useAxiosInstance';
import AuthContext from "../../../context/authContext/AuthContext";
import LoadingSpinner2 from "../../loadingAnimation/loading-spinner-2/LoadingSpinner2";
import BackButtonHeader from "../../backButtonHeader/BackButtonHeader";

function ChangePassword() {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [passwordChangeStatus, setPasswordChangeStatus] = useState(false);
    const navigate = useNavigate();

    const { userInfo, updateUserInfo } = useContext(AuthContext);
    const axiosInstance = useAxiosInstance();

    // State to track password validation criteria
    const [passwordValidation, setPasswordValidation] = useState({
        hasUpperCase: false,
        hasLowerCase: false,
        hasDigit: false,
        hasSpecialChar: false
    });

    const [passwordValidationState, setPasswordValidationState] = useState(true);

    const validatePassword = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasDigit = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*().]/.test(password);

        setPasswordValidation({
            hasUpperCase,
            hasLowerCase,
            hasDigit,
            hasSpecialChar
        });

        const isValid = hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar;
        setPasswordValidationState(isValid);

        return isValid;
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();

        if (oldPassword.trim() === '') {
            setError("Please enter Old Password");
            setShowPopup(true);
            return;
        }
        if (newPassword.trim() === '') {
            setError("Please enter New Password");
            setShowPopup(true);
            return;
        }
        if (!passwordValidationState) {
            setError("Password must have at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character.");
            setShowPopup(true);
            return;
        }
        if (!passwordsMatch) {
            setError("Passwords do not match");
            setShowPopup(true);
            return;
        }

        setLoading(true);

        const ChangePasswordRequestDto = {
            userId: userInfo.userId,
            newPassword: newPassword,
            oldPassword: oldPassword
        };

        try {
            const response = await axiosInstance.post('/api/user/update/password', ChangePasswordRequestDto);

            if (response.data.data === 'SUCCESSFUL') {
                setError(response.data.message || 'Password changed successfully!');
                setShowPopup(true);
                setPasswordChangeStatus(true);
            } else {
                setError(response.data.message || 'Failed to change password.');
                setShowPopup(true);
            }
        } catch (error) {
            console.error('Error while changing password:', error);
            setError('An error occurred. Please try again.');
            setShowPopup(true);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        setPasswordsMatch(newConfirmPassword === newPassword);
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setNewPassword(password);
        validatePassword(password);
    };

    const handleClosePopup = (e) => {
        setShowPopup(false);
        if (passwordChangeStatus) {
            navigate('/');
            const nullData = '';
            updateUserInfo(nullData, nullData, nullData, nullData, nullData, nullData, false, nullData, nullData);
        }
    }

    return (
        <><BackButtonHeader/>
        <div id="change-password-page">
            <div className="change-password-page-container">
                <h1 className="change-password-user-name">Hi {userInfo.firstName}</h1>
                <div className="change-password-user-email"><p>{userInfo.email}</p></div>
                <div className="change-password-header">To continue, first verify it's you </div>
                <form onSubmit={handlePasswordSubmit} id="change-password-form">
                    <input
                        type="password"
                        placeholder="Old password"
                        required
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        placeholder="New password"
                        required
                        value={newPassword}
                        onChange={handlePasswordChange}
                    />
                    {!passwordValidationState && (
                        <div className="password-validation-status">Password must have at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character.</div>
                    )}
                    <input
                        type="password"
                        placeholder="Confirm password"
                        required
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                    />
                    {!passwordsMatch && (
                        <div className="password-match-status">Passwords do not match.</div>
                    )}

                    <button
                        type="submit"
                        disabled={loading}
                    >
                        {loading ? <LoadingSpinner2 /> : 'Confirm'}
                    </button>

                    <button className='change-password-forgot-password-button' onClick={() => { navigate('/forgot-password') }} disabled={loading}>
                        Forgotten Password?
                    </button>
                </form>
            </div>

            {showPopup && (
                <Popup
                    message={error}
                    onClose={handleClosePopup}
                    handleClickOutside={handleClosePopup}
                />
            )}
        </div>
        </>
    );
}

export default ChangePassword;
