import './ChangeEmail.css';
import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import image from '../../../resources/verify-email-image.png';
import Popup from '../../popupbox/Popup';
import useAxiosInstance from '../../../configs/useAxiosInstance';
import AuthContext from '../../../context/authContext/AuthContext';
import OverlayLoadinSpinner1 from '../../loadingAnimation/0verlay-loading-spinner-1/OverlayLoadingSpinner1';
import BackButtonHeader from '../../backButtonHeader/BackButtonHeader';
import LoadingSpinner2 from '../../loadingAnimation/loading-spinner-2/LoadingSpinner2';

const ChangeEmail = () => {
    const navigate = useNavigate();
    const [sentOTP, setSentOtp] = useState(false);
    const [otp, setOtp] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const [message, setMessage] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);

    const { userInfo, updateUserInfo } = useContext(AuthContext);
    const [currentEmail, setCurrentEmail] = useState(userInfo.email);
    const [emailChanged, setEmailChanged] = useState(false);

    const [deviceInfo, setDeviceInfo] = useState({
        ipAddress: '',
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        deviceType: '',
        osName: '',
        osVersion: '',
        browserName: '',
        browserVersion: '',
        deviceId: '',
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        hardwareInfo: '',
        networkType: ''
    });

    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        const detectDeviceInfo = () => {
            const userAgent = navigator.userAgent;
            let deviceType = 'unknown';
            if (/mobile/i.test(userAgent)) {
                deviceType = 'mobile';
            } else if (/tablet/i.test(userAgent)) {
                deviceType = 'tablet';
            } else {
                deviceType = 'desktop';
            }
            setDeviceInfo(prev => ({
                ...prev,
                deviceType,
                osName: navigator.platform,
                browserName: userAgent.split(' ')[0]
            }));
        };

        detectDeviceInfo();
    }, []);

    // Email Validation Function
    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();  // Prevent default form submission behavior
        sentOTP ? handleChangeEmail() : handleSendOtp();
    }

    const handleSendOtp = async () => {
        setLoading(true);
        if (newEmail === currentEmail) {
            setMessage("Please enter a new Email");
            setShowPopup(true);
            setLoading(false);
            return;
        }

        if (!isValidEmail(newEmail)) {
            setMessage("Please enter a valid email address.");
            setShowPopup(true);
            setLoading(false);
            return;
        }

        const requestPayload = { email: newEmail, ...deviceInfo };

        try {
            const response = await axiosInstance.post('/api/register/send-otp/email', requestPayload);
            if (response.data.data === "SUCCESSFUL") {
                setSentOtp(true);
            } else {
                setMessage(response.data.message || "Failed to send OTP");
                setShowPopup(true);
            }
        } catch (error) {
            console.error(error);
            setMessage("Error sending OTP");
            setShowPopup(true);
        } finally {
            setLoading(false);
        }
    };

    const handleChangeEmail = async () => {
        setLoading(true);
        const userId = userInfo.userId;
        const changeEmailRequestDto = { otp, userId, newEmail };

        if (otp.trim() === '') {
            setMessage("Please enter OTP");
            setShowPopup(true);
            setLoading(false);
            return;
        }

        try {
            const response = await axiosInstance.post('/api/user/update/email', changeEmailRequestDto);
            if (response.data.data === 'SUCCESSFUL') {
                updateUserInfo(
                    userInfo.userId, userInfo.firstName, userInfo.lastName, newEmail, userInfo.username, userInfo.mobileNumber, true, userInfo.accountCreatedAt, userInfo.token
                );
                setEmailChanged(true);
            } else {
                setMessage(response.data.message || 'OTP verification failed. Please try again.');
                setShowPopup(true);
            }
        } catch (error) {
            console.error(error);
            setMessage("Error changing email");
            setShowPopup(true);
        } finally {
            setLoading(false);
        }
    };

    return (
        <><BackButtonHeader />
            <div className='change-email-page'>
                <div className='change-email-page-container'>
                    {sentOTP ? (
                        <>
                            <h2 className='change-email-header'>Verify Email</h2>
                            <span className="change-email-email-sent-to-div">Please enter OTP sent to</span>
                            <span className="change-email-sent-email-div">{newEmail}</span>
                        </>
                    ) : (
                        <h2 className='change-email-header'>Enter new email</h2>
                    )}
                    <form className='change-email-form' onSubmit={handleFormSubmit}>
                        <input
                            placeholder="Enter new email"
                            type="email"
                            value={newEmail}
                            onChange={(e) => setNewEmail(e.target.value)}
                            required
                            disabled={sentOTP || loading}
                        />
                        {sentOTP && (
                            <input
                                placeholder="Enter OTP"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                required
                                disabled={loading}
                            />
                        )}


                        {sentOTP ? (
                            <button
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? <LoadingSpinner2 /> : 'Verify'}
                            </button>
                        ) : (
                            <button
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? <LoadingSpinner2 /> : 'Send OTP'}
                            </button>
                        )}

                    </form>
                    {sentOTP
                        &&
                        <div className="verify-email-suggestion-div">If you haven't received the OTP, please check your Spam or Junk folder.</div>
                    }

                    {showPopup && (
                        <Popup
                            message={message}
                            onClose={() => { setShowPopup(false); }}
                            handleClickOutside={() => setShowPopup(false)}
                        />
                    )}
                    {emailChanged && (
                        <Popup
                            message={"Email changed successfully!"}
                            onClose={() => { navigate('/userprofile') }}
                            handleClickOutside={() => { navigate('/userprofile') }}
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ChangeEmail;
