import React, { useState, useEffect } from 'react';
import './ScreenBlinkEffect.css';

const ScreenBlinkEffect = ({ message }) => {
  const [isBlinking, setIsBlinking] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (message) {
      setIsBlinking(true);
      setShowMessage(true);

      // Set a timer to remove blink effect after 2 seconds
      const blinkTimer = setTimeout(() => {
        setIsBlinking(false);
      }, 2000);

      // Set a timer to hide the message after 3 seconds
      const messageTimer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);

      return () => {
        clearTimeout(blinkTimer);
        clearTimeout(messageTimer);
      };
    }
  }, [message]);

  return (
    <div className={`screen-blink ${isBlinking ? 'screen-blink-active' : ''}`}>
      {showMessage && (
        <div className={`blink-message ${showMessage ? 'blink-message-visible' : ''}`}>
          {message}
        </div>
      )}
    </div>
  );

};

export default ScreenBlinkEffect;
