import './LoadingSpinner2.css'

function LoadingSpinner2() {
    return (
        <div className='loading-spinner-2-container'>
          <div className='loading-spinner-2'></div>
        </div>
    );
}

export default LoadingSpinner2;