import React, { useContext } from 'react';
import AuthContext from '../../context/authContext/AuthContext';
import './UserGuideTimeline.css';
import CreateAccountIcon from '../../resources/welcomePageIcons/create-account-white-icon.png';
import GoToPageIcon from '../../resources/welcomePageIcons/go-to-page-white-icon.png';
import CopyLinkIcon from '../../resources/welcomePageIcons/copy-link-white-icon.png';
import PasteLinkIcon from '../../resources/welcomePageIcons/paste-link-white-icon.png';

const UserGuideTimeline2 = () => {
    const { userInfo } = useContext(AuthContext);

    // Define steps based on login status
    const steps = userInfo.isLoggedIn
        ? [
            {
                icon: GoToPageIcon,
                badge: 'Step 1',
                description: 'Go to the product page of your favorite product.',
            },
            {
                icon: CopyLinkIcon,
                badge: 'Step 2',
                description: 'Copy the product link from the share option.',
            },
            {
                icon: PasteLinkIcon,
                badge: 'Step 3',
                description: 'Paste the link in the "AddProduct" box and submit.',
            },
        ]
        : [
            {
                icon: CreateAccountIcon,
                badge: 'Step 1',
                description: 'Login or Register',
            },
            {
                icon: GoToPageIcon,
                badge: 'Step 2',
                description: 'Go to the product page of your favorite product.',
            },
            {
                icon: CopyLinkIcon,
                badge: 'Step 3',
                description: 'Copy the product link from the share option.',
            },
            {
                icon: PasteLinkIcon,
                badge: 'Step 4',
                description: 'Paste the link in the "AddProduct" box and submit.',
            },
        ];

    return (
        <div className="container">
            <div className="timeline">
                {steps.map((step, index) => (
                    <div className="timeline-container" key={index}>
                        <div className="timeline-icon">
                            <img src={step.icon} alt={step.badge} /> {/* Display imported icon */}
                        </div>
                        <div className="timeline-body">
                            <h4 className="timeline-title">
                                <span className="badge">{step.badge}</span>
                            </h4>
                            <p>{step.description}</p>
                            {/* <p className="timeline-subtitle">{step.time}</p> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default UserGuideTimeline2;
