import React from "react";
import image from "../../../resources/verify-email-image.png";
import "./VerifyEmail.css";

const VerifyEmail = ({ handleVerifyOtp, setOtp, otp, email, loading, setSentOtpFalse }) => {

  return (

    <div className="verify-email-page">
      <div className="verify-email-page-container">
        <form onSubmit={handleVerifyOtp} className="verify-email-form">
          <div className="verify-email-header-container">
            <img
              src={image}
              className="verify-email-image"
              alt="Email Verification"
            />
            <h2>Verify Email</h2>
            <span className="verify-email-email-sent-to-div">Please enter OTP sent to</span>
            <span className="verify-email-sent-email-div">{email}</span>
          </div>
          <input
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            required
          />
          <button type="submit">Verify</button>
        </form>
        <div className="verify-email-suggestion-div">If you haven't received the OTP, please check your Spam or Junk folder.</div>
        <button
          className="verify-email-back-button"
          onClick={() => {
            setSentOtpFalse();
            setOtp('');
          }}
          disabled={loading}
        >
          Back
        </button>
      </div>
    </div>
  );
};

export default VerifyEmail;
