import { useContext } from 'react';
import AuthContext from '../context/authContext/AuthContext';
import SettingContext from '../context/userSettingContext/SettingContext';

export const useLogout = () => {
    const { userInfo, updateUserInfo } = useContext(AuthContext);
    const {updateUserSetting} = useContext(SettingContext);

    const handleLogout = (closeSidebar) => {
        console.log('Logout function called'); // Check if this logs
        updateUserInfo('', '', '', '', '', '', false, '', '');
        updateUserSetting('','','');
        localStorage.removeItem(`productList_${userInfo?.userId}`);
        if (typeof closeSidebar === 'function') {
            closeSidebar();
        }
    };
    
    return { handleLogout };
};
