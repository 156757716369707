import { useMemo, useContext } from 'react';
import axios from 'axios';
import AuthContext from '../context/authContext/AuthContext';

const useAxiosInstance = () => {
    const { userInfo } = useContext(AuthContext);

    const axiosInstance = useMemo(() => {
        const instance = axios.create({
            // baseURL: 'http://localhost:8081',
            // baseURL: 'http://192.168.31.208:8081',
            baseURL: 'https://api.bargainbell.online', 
            headers: {
                'Content-Type': 'application/json',
            },
        });

        instance.interceptors.request.use((config) => {
            if (userInfo?.token && userInfo?.userId) {
                config.headers['Authorization'] = `Bearer ${userInfo.token}`;
                config.headers['AccountId'] = `${userInfo.userId}`;
            }
            return config;
        });

        return instance;
    }, [userInfo]);

    return axiosInstance;
};

export default useAxiosInstance;
