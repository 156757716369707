import React from 'react';
import './ConfirmationPopup.css'; // Import custom styles for the popup

const ConfirmationPopup = ({ message, onOkay, onClose, firstButtonLabel, secondButtonLabel, setShowPopup }) => {

    function handleClickOutside(e) {
        const popupBox = document.querySelector('.confirmation-popup-box');
        if (!popupBox.contains(e.target)) {
            setShowPopup(false);
        }
    }

    return (
        <>
            <div className="confirmation-popup-blur-background"></div>
            <div className="confirmation-popup-overlay" onClick={handleClickOutside}>
                <div className="confirmation-popup-box">
                    <p className="confirmation-popup-message">{message}</p>
                    <div className='confirmation-popup-buttons-div'>
                        <button className="confirmation-popup-okay-button" onClick={onOkay}>{firstButtonLabel}</button>
                        <button className="confirmation-popup-close-button" onClick={onClose}>{secondButtonLabel}</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ConfirmationPopup;
