import './TryNowCarousel.css';
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/authContext/AuthContext';
import MyntraIcon from '../../resources/myntra-icon.png';
import AmazonIcon from '../../resources/amazon-icon.png';
import FlipkartIcon from '../../resources/flipkart-icon.png';
import RightArrowicon from '../../resources/welcomePageIcons/right-arrow-icon-white.png';

const TryNowCarousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const { userInfo } = useContext(AuthContext);
    const navigate = useNavigate();

    const platforms = [
        {
            name: 'Amazon',
            url: 'https://www.amazon.in',
            icon: AmazonIcon
        },
        {
            name: 'Flipkart',
            url: 'https://www.flipkart.com',
            icon: FlipkartIcon
        },
        {
            name: 'Myntra',
            url: 'https://www.myntra.com',
            icon: MyntraIcon
        }
    ];

    const handleNextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % 2);
    };

    const handlePlatformClick = (url) => {
        navigate('/addproduct');
        window.open(url, '_blank');
    };

    return (
        <div className="carousel-container">
            <div className={`carousel-slide ${currentSlide === 0 ? 'active-slide-left' : 'hidden-slide-left'}`}>
                <button onClick={handleNextSlide} className='trynow-carousel-trynow-button'>
                    Try it Now <img src={RightArrowicon} alt="Next Slide Icon" />
                </button>
            </div>

            <div className={`carousel-slide ${currentSlide === 1 ? 'active-slide-right' : 'hidden-slide-right'}`}>
                {userInfo.isLoggedIn ? (
                    <div>
                        <div className='carousel-slide-platform-buttons'>
                            {platforms.map((platform, index) => (
                                <div key={index} className="platform-icon" onClick={() => handlePlatformClick(platform.url)}>
                                    <img src={platform.icon} alt={`${platform.name} Icon`} />
                                </div>
                            ))}
                        </div>
                        <p>Choose platform</p>
                    </div>
                ) : (
                    <div className='carousel-slide-auth-buttons'>
                        <button onClick={() => navigate('/signin')} className='auth-button'>Login</button>
                        <p>or</p>
                        <button onClick={() => navigate('/register')} className='auth-button'>Create account</button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TryNowCarousel;
