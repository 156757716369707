import React, { useContext } from 'react';
import './WelcomePageNavbar.css';
// import NameLogo from '../../../resources/name-logo-2.png';
import NameLogo from '../../../../resources/name-logo-coloured-icon-cropped.png';
import menuIcon from '../../../../resources/menu-icon.png';

function WelcomePageNavbar({ isSidebarOpen, openSidebar, closeSidebar, handleLogout }) {

    return (
        <div className="welcome-page-navbar-main-div">
            <div className="welcome-page-navbar-logo-div">
                <a href="/">
                    <img src={NameLogo} alt="Logo" />
                </a>
            </div>

            <div className="welcome-page-navbar-links-main-div">
                <div className="welcome-page-navbar-links-div"><a href="/">Home</a></div>
                <div className="welcome-page-navbar-links-div"><a href="/addproduct">Add Product</a></div>
                <div className="welcome-page-navbar-links-div"><a href="/watchlist">My Watchlist</a></div>
                <div className="welcome-page-navbar-links-div"><a href="/userprofile">User Profile</a></div>
                <div className="welcome-page-navbar-logout-div"><button onClick={() => handleLogout(closeSidebar)}>Logout</button></div>
            </div>

            <div className="welcome-page-navbar-menu-icon-div">
                <img src={menuIcon} alt="Menu" onClick={isSidebarOpen ? closeSidebar : openSidebar} />
            </div>
        </div>
    );
}

export default WelcomePageNavbar;
