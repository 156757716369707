import React, { useEffect, useState } from 'react';
import './ToggleButton.css'

const AnimatedToggle = ({ isChecked }) => {
  return (
    <div className="animated-toggle" style={{ width: '40px', height: '20px' }}>
      <input
        id="cbx"
        type="checkbox"
        checked={isChecked}
        readOnly // Prevent direct user interaction
      />
      <label className="toggle" htmlFor="cbx">
        <span></span>
      </label>
    </div>
  );
};

export default AnimatedToggle;
