import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';
import './priceChart.css';
import useAxiosInstance from '../../configs/useAxiosInstance';
import LoadingSpinner1 from '../loadingAnimation/loading-spinner-1/LoadingSpinner1';

const CACHE_EXPIRY_TIME = 1 * 60 * 1000; // 1 minutes in milliseconds

const PriceChart = ({ productId, platform }) => {
    const [priceData, setPriceData] = useState([]);
    const [filteredPriceData, setFilteredPriceData] = useState([]);
    const [error, setError] = useState(null);
    const [timeRange, setTimeRange] = useState('1W');
    const [chartLoading, setChartLoading] = useState(true);
    const [lowestPrice, setLowestPrice] = useState(null);
    const [highestPrice, setHighestPrice] = useState(null);

    const chartRef = useRef(null);  // Create a ref for the canvas
    const axiosInstance = useAxiosInstance();

    // Function to get cached data from localStorage
    const getCachedData = (key) => {
        const cached = localStorage.getItem(key);
        if (cached) {
            const parsed = JSON.parse(cached);
            const now = new Date().getTime();

            // Check if the cache is still valid (within 5 minutes)
            if (now - parsed.timestamp < CACHE_EXPIRY_TIME) {
                return parsed.data;
            } else {
                // If the cache is expired, remove it
                localStorage.removeItem(key);
            }
        }
        return null;
    };

    // Function to set data in localStorage with a timestamp
    const setCachedData = (key, data) => {
        const cachedData = {
            data,
            timestamp: new Date().getTime(), // Store the current timestamp
        };
        localStorage.setItem(key, JSON.stringify(cachedData));
    };

    useEffect(() => {
        const fetchPriceData = async () => {
            setChartLoading(true);
            const cacheKey = `priceData_${productId}`;

            // Check for cached data first
            const cachedData = getCachedData(cacheKey);
            if (cachedData) {
                setPriceData(cachedData);
                setFilteredPriceData(cachedData);
                setChartLoading(false);
                return;
            }

            // If no valid cache, fetch data from the API
            try {
                const response = await axiosInstance.post(
                    `/api/product/get-price-list`,
                    null,
                    {
                        params: {
                            productId: encodeURIComponent(productId),
                            platform: encodeURIComponent(platform),
                        },
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                );

                if (response.data.status === 200) {
                    const fetchedData = response.data.data;
                    setPriceData(fetchedData);
                    setFilteredPriceData(fetchedData);

                    // Store the fetched data in localStorage
                    setCachedData(cacheKey, fetchedData);
                } else {
                    throw new Error(response.data.message || 'Failed to fetch price data');
                }
            } catch (error) {
                setError(error.message || 'An error occurred');
            } finally {
                setChartLoading(false);
            }
        };

        if (productId && platform) {
            fetchPriceData();
        }
    }, [productId, platform]);

    // Function to filter data based on the selected time range
    // Function to filter data based on the selected time range and get the highest/lowest price
    // Function to filter data based on the selected time range and get unique price points
    const filterDataByRange = (range) => {
        const now = new Date();
        let filteredData = [];

        // Get the date range based on the selected time range
        let timeLimit;
        if (range === '1W') {
            // Last 1 week - no filtering for duplicates
            timeLimit = new Date(now.setDate(now.getDate() - 7));
            filteredData = priceData.filter(entry => new Date(entry.timestamp) >= timeLimit);
        } else {
            // Last 1 month, 3 months, or 6 months - filter for unique prices
            if (range === '1M') {
                timeLimit = new Date(now.setMonth(now.getMonth() - 1));
            } else if (range === '3M') {
                timeLimit = new Date(now.setMonth(now.getMonth() - 3));
            } else if (range === '6M') {
                timeLimit = new Date(now.setMonth(now.getMonth() - 6));
            }

            // Filter data based on the selected time range and track unique price changes
            let lastPrice = null;

            filteredData = priceData.filter(entry => {
                const entryDate = new Date(entry.timestamp);
                const isWithinRange = entryDate >= timeLimit;

                // Check if the price is different from the last recorded price
                const isPriceChanged = lastPrice === null || entry.price !== lastPrice;

                // Update lastPrice if the current entry is within range and has a different price
                if (isWithinRange && isPriceChanged) {
                    lastPrice = entry.price;
                    return true; // Include this entry in filtered data
                }
                return false; // Exclude this entry
            });
        }

        setFilteredPriceData(filteredData);
        setTimeRange(range);

        // Find the highest and lowest prices in the filtered data
        if (filteredData.length > 0) {
            const highest = filteredData.reduce((max, entry) => entry.price > max.price ? entry : max, filteredData[0]);
            const lowest = filteredData.reduce((min, entry) => entry.price < min.price ? entry : min, filteredData[0]);

            setHighestPrice(highest);
            setLowestPrice(lowest);
        }
    };

    useEffect(() => {
        if (priceData.length > 0) {
            filterDataByRange('1W'); // Default to 1 month range on load
        }
    }, [priceData]);



    useEffect(() => {
        if (!chartLoading && chartRef.current && filteredPriceData.length > 0) {
            const ctx = chartRef.current.getContext('2d');
            const existingChart = Chart.getChart(chartRef.current); // Get the existing chart, if any

            if (existingChart) {
                existingChart.destroy(); // Destroy the existing chart before rendering a new one
            }

            const sortedData = [...filteredPriceData].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

            const labels = sortedData.map(entry => new Date(entry.timestamp).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }));

            const datasetValues = sortedData.map(entry => entry.price);

            const maxPrice = Math.max(...datasetValues);
            const minPrice = Math.min(...datasetValues);

            const yMin = Math.floor(minPrice / 100) * 100;
            const yMax = Math.ceil(maxPrice / 100) * 100;

            const config = {
                type: 'line',
                data: {
                    labels: labels,
                    datasets: [{
                        label: 'Price',
                        data: datasetValues,
                        borderColor: 'rgba(255, 255, 255)',
                        backgroundColor: 'rgba(255, 255, 255)',
                        borderWidth: 1,
                        pointRadius: 3,
                        pointBorderColor: 'rgba(3, 169, 244, 1)',
                        pointBackgroundColor: 'white',
                        pointHoverRadius: 7,
                        pointHoverBackgroundColor: 'rgba(3, 169, 244, 0.8)',
                    }]
                },
                options: {
                    interaction: {
                        mode: 'index',  // Changed from 'nearest' to 'index'
                        intersect: false,  // Ensure we get the point closest to the x-axis
                    },
                    scales: {
                        x: {
                            border: {
                                color: '#ffffff', // Color for the x-axis line
                            },
                            ticks: {
                                display: false,
                                autoSkip: true,
                                maxTicksLimit: 1,
                                color: '#ffffff',
                            }
                        },
                        y: {
                            beginAtZero: false,
                            min: yMin,
                            max: yMax,
                            border: {
                                color: '#ffffff', // Color for the y-axis line
                            },
                            ticks: {
                                display: false,
                                autoSkip: true,
                                maxTicksLimit: 1,
                                color: '#ffffff',
                                stepSize: 100,
                                callback: (value) => `₹${value}`,
                            }
                        }
                    },
                    elements: {
                        line: {
                            tension: 0.4,
                        }
                    },
                    plugins: {
                        tooltip: {
                            enabled: true,
                            callbacks: {
                                label: function (tooltipItem) {
                                    const dataPoint = sortedData[tooltipItem.dataIndex]; // Get the corresponding data point
                                    const price = `₹${dataPoint.price}`;
                                    const date = new Date(dataPoint.timestamp).toLocaleDateString('en-US', {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    });
                                    const time = new Date(dataPoint.timestamp).toLocaleTimeString('en-US', {
                                        hour: 'numeric',
                                        minute: 'numeric',
                                        hour12: true,
                                    });
                                    return [`Price: ${price}`, `Date: ${date}`, `Time: ${time}`]; // Format the tooltip to show price, date, and time
                                }
                            },
                            intersect: false,
                            mode: 'index',
                        },
                    }
                },
                plugins: [{
                    id: 'crosshairPlugin',
                    afterDatasetsDraw(chart) {
                        if (chart.tooltip._active && chart.tooltip._active.length) {
                            const ctx = chart.ctx;
                            const activePoint = chart.tooltip._active[0];
                            const x = activePoint.element.x;
                            const topY = chart.scales.y.top;
                            const bottomY = chart.scales.y.bottom;

                            // Draw the vertical line
                            ctx.save();
                            ctx.beginPath();
                            ctx.moveTo(x, topY);
                            ctx.lineTo(x, bottomY);
                            ctx.lineWidth = 1;
                            ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
                            ctx.stroke();
                            ctx.restore();
                        }
                    }
                }]
            };

            new Chart(ctx, config);  // Create the new chart
        }
    }, [filteredPriceData, chartLoading]);

    const formatTime = (dateTime) => {
        return new Date(dateTime).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    const formatDate = (dateTime) => {
        return new Date(dateTime).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };


    return (
        <div className="price-chart-main-div">
            {chartLoading ? (
                <div className='price-chart-loading-spinner-div'>
                    <LoadingSpinner1 />
                </div>
            ) : (<>
                <div className="price-chart-min-max-price-div">
                    {highestPrice ? (
                        <>
                            <span className="price-info-label">Max Price: &nbsp;</span>
                            <span className="price-info-value">₹{highestPrice.price} &nbsp;</span>
                            <span className="date-time-info">
                                on {formatDate(highestPrice.timestamp)} ({formatTime(highestPrice.timestamp)})
                            </span>
                        </>
                    ) : (
                        'Loading highest price...'
                    )}
                </div>

                <div className="price-chart-container">
                    <canvas ref={chartRef} id="priceChart"></canvas>  {/* Use ref instead of getElementById */}
                </div>

                <div className="price-chart-min-max-price-div">
                    {lowestPrice ? (
                        <>
                            <span className="price-info-label">Min Price: &nbsp;</span>
                            <span className="price-info-value">₹{lowestPrice.price} &nbsp;</span>
                            <span className="date-time-info">
                                on {formatDate(lowestPrice.timestamp)} ({formatTime(lowestPrice.timestamp)})
                            </span>
                        </>
                    ) : (
                        'Loading lowest price...'
                    )}
                </div>

            </>
            )}

            <div className="price-chart-time-range-buttons">
                <button onClick={() => filterDataByRange('1W')} className={timeRange === '1W' ? 'active' : ''}>
                    1w
                </button>
                <button onClick={() => filterDataByRange('1M')} className={timeRange === '1M' ? 'active' : ''}>
                    1m
                </button>
                <button onClick={() => filterDataByRange('3M')} className={timeRange === '3M' ? 'active' : ''}>
                    3m
                </button>
                <button onClick={() => filterDataByRange('6M')} className={timeRange === '6M' ? 'active' : ''}>
                    6m
                </button>
            </div>
        </div>
    );
};

PriceChart.propTypes = {
    productId: PropTypes.string.isRequired,
    platform: PropTypes.string.isRequired,
};

export default PriceChart;