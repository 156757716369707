import "./forgotPassword.css";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import image from "../../resources/verify-email-image.png";
import Popup from "../popupbox/Popup";
import useAxiosInstance from "../../configs/useAxiosInstance";
import OverlayLoadinSpinner1 from "../loadingAnimation/0verlay-loading-spinner-1/OverlayLoadingSpinner1";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [sentOTP, setSentOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passwordValidationState, setPasswordValidationState] = useState(true);
  const axiosInstance = useAxiosInstance();

  // State to track password validation criteria
  const [passwordValidation, setPasswordValidation] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasDigit: false,
    hasSpecialChar: false,
  });

  const [deviceInfo, setDeviceInfo] = useState({
    ipAddress: "",
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    language: navigator.language,
    deviceType: "",
    osName: "",
    osVersion: "",
    browserName: "",
    browserVersion: "",
    deviceId: "",
    screenResolution: `${window.screen.width}x${window.screen.height}`,
    hardwareInfo: "",
    networkType: "",
  });

  useEffect(() => {
    const detectDeviceInfo = () => {
      const userAgent = navigator.userAgent;
      let deviceType = "unknown";
      if (/mobile/i.test(userAgent)) {
        deviceType = "mobile";
      } else if (/tablet/i.test(userAgent)) {
        deviceType = "tablet";
      } else {
        deviceType = "desktop";
      }
      setDeviceInfo((prev) => ({
        ...prev,
        deviceType,
        osName: navigator.platform,
        browserName: userAgent.split(" ")[0], // Simplified browser name detection
      }));
    };

    detectDeviceInfo();
  }, []);

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*().]/.test(password);

    setPasswordValidation({
      hasUpperCase,
      hasLowerCase,
      hasDigit,
      hasSpecialChar,
    });

    setPasswordValidationState(
      hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar
    );

    return hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar;
  };

  const isValidOtp = (otp) => {
    const otpRegex = /^\d{6}$/;
    return otpRegex.test(otp) && otp.length === 6;
  };

  const handleSendOtp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!isValidEmail(email)) {
      setMessage("Please enter a valid email.");
      setShowPopup(true);
      setLoading(false);
      return;
    }

    const requestPayload = { email, ...deviceInfo };

    try {
      const response = await axiosInstance.post(
        "api/auth/send-otp/email",
        requestPayload
      );

      switch (response.data.data) {
        case "SUCCESSFUL":
          setSentOtp(true);
          break;
        case "WRONG_OTP":
          setMessage("Incorrect OTP");
          setShowPopup(true);
          break;
        case "INVALID_INPUT":
          setMessage("Incorrect email!");
          setShowPopup(true);
          break;
        case "TOO_MANY_REQUESTS":
          setMessage("OTP limit reached, please try again later.");
          setShowPopup(true);
          break;
        default:
          setMessage("Failed to send OTP, please try again later.");
          setShowPopup(true);
          break;
      }
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
      setShowPopup(true);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!isValidOtp(otp)) {
      setMessage("Please enter a valid 6-digit OTP.");
      setShowPopup(true);
      setLoading(false);
      return;
    }

    if (!isValidPassword(newPassword)) {
      setMessage(
        "Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
      );
      setShowPopup(true);
      setLoading(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage("Passwords do not match.");
      setShowPopup(true);
      setLoading(false);
      return;
    }

    const resetPasswordRequestDto = { email, newPassword, otp };

    try {
      const response = await axiosInstance.post(
        "api/auth/reset-password",
        resetPasswordRequestDto
      );

      if (response.data.data === "VERIFIED") {
        setMessage("Password Reset Successful!");
        setShowPopup(true);
        navigate("/signin");
      } else if (response.data.data === "SAME_PASSWORD") {
        setMessage("You've entered your current password. Please choose a new password or log in with the existing one.");
        setShowPopup(true);
      }
      else {
        setMessage("OTP verification failed. Please try again.");
        setShowPopup(true);
      }
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      setShowPopup(true);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);
    isValidPassword(password);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(newPassword === e.target.value);
  };

  return (
    <div className="forgot-password-page">
      {!sentOTP ? (
        <div className="forgot-password-page-container">
          <form onSubmit={handleSendOtp} className="forgot-password-form">
            <div className="forgot-password-header-container">
              <h1>Find your account</h1>
            </div>
            <input
              placeholder="Enter your email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={loading || sentOTP}
            />
            <button type="submit">Send OTP</button>
          </form>
          <button
            className="forgot-password-back-button"
            onClick={() => {
              navigate("/signin");
            }}
            disabled={loading}
          >
            Back
          </button>
        </div>
      ) : (
        <div className="forgot-password-page-container">
          <form onSubmit={handleVerifyOtp} className="forgot-password-form">
            <div className="forgot-password-header-container">
              <img
                src={image}
                className="forgot-password-image"
                alt="Email Verification"
              />
              {/* <h2>Verify Email</h2> */}
              <span className="forgot-password-email-sent-to-div">Please enter OTP sent to</span>
              <span className="forgot-password-sent-email-div">{email}</span>
            </div>
            <input
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="Enter new password"
              value={newPassword}
              onChange={handlePasswordChange}
              required
            />
            {!passwordValidationState && (
              <div className="password-validation-status">
                Password must have at least 1 uppercase letter, 1 lowercase
                letter, 1 digit, and 1 special character.
              </div>
            )}
            <input
              type="password"
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
            {!passwordsMatch && (
              <div className="password-match-status">
                Passwords do not match.
              </div>
            )}
            <button type="submit">Reset Password</button>
          </form>
          <div className="verify-email-suggestion-div">If you haven't received the OTP, please check your Spam or Junk folder.</div>
          <button
            className="forgot-password-back-button"
            onClick={() => {
              setSentOtp(false);
            }}
            disabled={loading}
          >
            Back
          </button>
        </div>
      )}
      {showPopup && (
        <Popup
          message={message}
          onClose={() => setShowPopup(false)}
          handleClickOutside={() => setShowPopup(false)}
        />
      )}

      {loading && <OverlayLoadinSpinner1 />}
    </div>
  );
};

export default ForgotPassword;
