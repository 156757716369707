import React, { useState, useEffect } from "react";
import AuthContext from "./AuthContext";

const AuthDataContext = (props) =>{

    const storedUserInfo = localStorage.getItem('userInfo');

    const defaultUserInfo = storedUserInfo ? JSON.parse(storedUserInfo) : {
        "userId":"",
        "firstName":"",
        "lastName":"",
        "email": "",
        "username": "",
        "mobileNumber":"",
        "isLoggedIn": false,
        "accountCreatedAt":"",
        "token":""
    };
    
    const [userInfo, setUserInfo] = useState(defaultUserInfo);

    useEffect(() => {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
    }, [userInfo]);

    const updateUserInfo = (userId, firstName, lastName, email, username, mobileNumber, isLoggedIn, accountCreatedAt, token) => {
        const newUserInfo = {
            ...userInfo,
            userId: userId,
            firstName: firstName,
            lastName: lastName,
            email: email,
            username: username,
            mobileNumber: mobileNumber,
            isLoggedIn: isLoggedIn,
            accountCreatedAt: accountCreatedAt,
            token: token
        };
        setUserInfo(newUserInfo);
        localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
    };


    return(
        <AuthContext.Provider value={{userInfo, updateUserInfo}}>
            {props.children}
        </AuthContext.Provider>
    );


};

export default AuthDataContext;
