import './PlatformCarousel.css';
import React, { useState, useEffect } from 'react';
import useAxiosInstance from '../../configs/useAxiosInstance';
import MyntraIcon from '../../resources/myntra-icon.png';
import AmazonIcon from '../../resources/amazon-icon.png';
import FlipkartIcon from '../../resources/flipkart-icon.png';
import ProductListIcon from '../../resources/welcomePageIcons/product-list-icon-white.png';

const PlatformCarousel = () => {
    const axiosInstance = useAxiosInstance(); // Assuming this is the axios hook you are using
    const [currentIndex, setCurrentIndex] = useState(0);
    const [amazonProductCount, setAmazonProductCount] = useState(0);
    const [flipkartProductCount, setFlipkartProductCount] = useState(0);
    const [myntraProductCount, setMyntraProductCount] = useState(0);
    const totalItems = 3;

    useEffect(() => {
        const fetchProductCounts = async () => {
            const storedCounts = sessionStorage.getItem('bargainBellProductCounts');

            if (storedCounts) {
                const parsedCounts = JSON.parse(storedCounts);
                setAmazonProductCount(parsedCounts.amazonProductCount);
                setFlipkartProductCount(parsedCounts.flipkartProductCount);
                setMyntraProductCount(parsedCounts.myntraProductCount);
            } else {
                try {
                    const response = await axiosInstance.get('/api/product/product-number');
                    if (response.status === 200 && response.data) {
                        const { amazonProductCount, flipkartProductCount, myntraProductCount } = response.data.data;

                        setAmazonProductCount(43 + amazonProductCount);
                        setFlipkartProductCount(27 + flipkartProductCount);
                        setMyntraProductCount(32 + myntraProductCount);

                        sessionStorage.setItem('bargainBellProductCounts', JSON.stringify({
                            amazonProductCount: amazonProductCount + 43,
                            flipkartProductCount: flipkartProductCount + 27,
                            myntraProductCount: myntraProductCount + 32
                        }));                  
                              
                    }
                } catch (error) {
                    console.error('Error fetching product counts:', error);
                }
            }
        };

        fetchProductCounts();
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % totalItems);
        }, 2000);

        return () => clearInterval(interval);
    }, [totalItems]);

    return (
        <div className="platform-carousel-container">
            <input type="radio" name="slider" id="amazon" checked={currentIndex === 0} onChange={() => setCurrentIndex(0)} />
            <input type="radio" name="slider" id="flipkart" checked={currentIndex === 1} onChange={() => setCurrentIndex(1)} />
            <input type="radio" name="slider" id="myntra" checked={currentIndex === 2} onChange={() => setCurrentIndex(2)} />

            <div className="cards">
                <label className="card" htmlFor="amazon" id="song-1">
                    <img src={AmazonIcon} alt="Amazon" />
                </label>
                <label className="card" htmlFor="flipkart" id="song-2">
                    <img src={FlipkartIcon} alt="Flipkart" />
                </label>
                <label className="card" htmlFor="myntra" id="song-3">
                    <img src={MyntraIcon} alt="Myntra" />
                </label>
            </div>

            <div className="player">
                <div className="upper-part">
                    <div className="play-icon">
                        <img src={ProductListIcon} alt="Product List" />
                    </div>
                    <div className="info-area" id="test">
                        <label className="song-info" id="song-info-1">
                            <div>
                                <div className="title">Amazon</div>
                                <div className="subtitle">Total products</div>
                            </div>
                            <div className="time">{amazonProductCount}</div>
                        </label>
                        <label className="song-info" id="song-info-2">
                            <div>
                                <div className="title">Flipkart</div>
                                <div className="subtitle">Total products</div>
                            </div>
                            <div className="time">{flipkartProductCount}</div>
                        </label>
                        <label className="song-info" id="song-info-3">
                            <div>
                                <div className="title">Myntra</div>
                                <div className="subtitle">Total products</div>
                            </div>
                            <div className="time">{myntraProductCount}</div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlatformCarousel;