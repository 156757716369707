import React, { useState, useContext } from 'react';
import AuthContext from '../../context/authContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import useAxiosInstance from '../../configs/useAxiosInstance.js';
import './addProduct.css';
import Popup from '../popupbox/Popup.js';
import MyntraIcon from '../../resources/myntra-icon.png';
import AmazonIcon from '../../resources/amazon-icon.png';
import FlipkartIcon from '../../resources/flipkart-icon.png';
import AddProductLoadingSpinner from './loadingSpinner/AddProductLoadingSpinner.js';
import Header from '../header/Header.js';

const AddProduct = () => {

  const navigate = useNavigate();
  const [productName, setProductName] = useState('');
  const [productId, setProductId] = useState('');
  const [limitPrice, setLimitPrice] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userInfo } = useContext(AuthContext);

  const axiosInstance = useAxiosInstance();

  // Validate form inputs
  const validateInputs = () => {
    // Ensure all fields are filled
    if (!productName || !productId || !limitPrice || !platform) {
      setPopupMessage('All fields are required.');
      setShowPopup(true);
      return false;
    }

    // MYNTRA validation: Product ID must be 7 or 8 digits (numbers only)
    if (platform === 'MYNTRA') {
      if (!/^\d{7,8}$/.test(productId)) {
        setPopupMessage('Product Code must be 7 or 8 digits and numbers only.');
        setShowPopup(true);
        return false;
      }
      return true;
    }

    // AMAZON validation
    if (platform === 'AMAZON') {
      if (productId.startsWith("https://amzn.in/d/")) {
        const deepUrlRegex = /^https:\/\/amzn\.in\/d\/[a-zA-Z0-9]{7}$/;
        if (!deepUrlRegex.test(productId)) {
          setPopupMessage('Invalid URL, Please check and provide a valid URL.');
          setShowPopup(true);
          return false;
        }
        return true;
      }

      if (productId.startsWith("https://www.amazon.in") || productId.startsWith("www.amazon.in")) {
        const fullUrlRegex = /^https:\/\/www\.amazon\.in\/.*\/dp\/([A-Z0-9]{10})/;
        const match = productId.match(fullUrlRegex);
        if (match && match[1] && isValidAsin(match[1])) {
          setProductId(match[1]);  // Set the ASIN
          return true;
        }
        setPopupMessage('Invalid or missing ASIN in the URL. Ensure the URL contains a valid ASIN.');
        setShowPopup(true);
        return false;
      }

      if (isValidAsin(productId)) {
        return true;
      }

      setPopupMessage('Invalid URL or ASIN. Please check and provide a valid Amazon link or ASIN.');
      setShowPopup(true);
      return false;
    }

    // FLIPKART validation
    if (platform === 'FLIPKART') {
      if (productId.startsWith("https://dl.flipkart.com/")) {
        const regex = /^https:\/\/dl\.flipkart\.com\/[a-zA-Z]+\/.{10}$/;
        if (!regex.test(productId)) {
          setPopupMessage('Invalid URL, Please check and provide a valid URL.');
          setShowPopup(true);
          return false;
        }
        return true;
      }

      if (productId.startsWith("https://www.flipkart.com") || productId.startsWith("www.flipkart.com")) {
        const regex = /[?&]pid=([A-Z0-9]{16})/;
        const match = productId.match(regex);
        if (match && match[1] && isValidPid(match[1])) {
          setProductId(match[1]);  // Set the PID
          return true;
        }
        setPopupMessage('Invalid or missing PID in the URL. Ensure the URL contains a valid PID.');
        setShowPopup(true);
        return false;
      }

      if (isValidPid(productId)) {
        return true;
      }

      setPopupMessage('Invalid URL or PID. Please check and provide a valid Flipkart link or PID.');
      setShowPopup(true);
      return false;
    }

    function isValidAsin(asin) {
      const asinRegex = /^[A-Z0-9]{10}$/;
      return asinRegex.test(asin);
    }

    function isValidPid(pid) {
      const pidRegex = /^[A-Z0-9]{16}$/;
      return pidRegex.test(pid);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateInputs()) {
      setLoading(false);
      return;
    }

    const productData = {
      userId: userInfo.userId,
      productName: productName,
      productId: productId,
      platform: platform,
      limitPrice: parseFloat(limitPrice)
    };

    try {
      const response = await axiosInstance.post('/api/product/add', productData);
      console.log(response.data.data);
      const responseData = response.data;

      if (responseData.data === "SUCCESSFUL") {
        setPopupMessage(responseData.message || 'Product added successfully!');
        setIsSuccess(true);
        setShowPopup(true);

        // Clear form fields
        setProductName('');
        setProductId('');
        setLimitPrice('');
        setPlatform(null);

        // Clear localStorage product list and notify other tabs
        const productListKey = `productList_${userInfo.userId}`;
        localStorage.removeItem(productListKey);

        localStorage.setItem(`productList_${userInfo.userId}_updated`, Date.now());

      } else if (responseData.data === "ALREADY_PRESENT") {
        setPopupMessage("This product is already present in your Watchlist!");
        setIsSuccess(false);
        setShowPopup(true);
      } else if (responseData.data === "LIMIT_REACHED") {
        setPopupMessage("You've reached the limit of 20 products in your watchlist. To add a new product, please remove an existing one.");
        setIsSuccess(false);
        setShowPopup(true);
      } else {
        setPopupMessage(responseData.message || 'Failed to add product.');
        setIsSuccess(false);
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error while adding product:', error);
      setPopupMessage('An error occurred. Please try again.');
      setIsSuccess(false);
      setShowPopup(true);
    } finally {
      setLoading(false);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    if (isSuccess) {
      navigate('/watchlist');
    }
  };

  return (
    <><Header/>
    <div className='add-product-page'>
      <div className="add-product-page-container">
        <div id="add-product-header-div">
          <h1>Add Product</h1>
        </div>
        <div className='add-product-form-container'>
          <form className="add-product-form" onSubmit={handleSubmit}>
            <label className="add-product-label">
              Product Name:
              <input
                className="add-product-input"
                type="text"
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                required
              />
            </label>
            <label className="add-product-label">
              <p>Platform:</p>
              <button
                className={platform === "MYNTRA" ? "selected" : ""}
                onClick={() => setPlatform("MYNTRA")}
                type="button"
              >
                <img src={MyntraIcon} alt="Myntra" width="30" height="30" />
              </button>

              <button
                className={platform === "AMAZON" ? "selected" : ""}
                onClick={() => setPlatform("AMAZON")}
                type="button"
              >
                <img src={AmazonIcon} alt="Amazon" width="30" height="30" />
              </button>

              <button
                className={platform === "FLIPKART" ? "selected" : ""}
                onClick={() => setPlatform("FLIPKART")}
                type="button"
              >
                <img src={FlipkartIcon} alt="Flipkart" width="30" height="30" />
              </button>
            </label>

            {platform && (
              <label className="add-product-label">
                {platform === 'MYNTRA' && "Product URL / Product Code"}
                {platform === 'AMAZON' && "Product URL / ASIN"}
                {platform === 'FLIPKART' && "Product URL / PID"}
                <input
                  className="add-product-input"
                  type="text"
                  value={productId}
                  onChange={(e) => setProductId(e.target.value)}
                  required
                />
              </label>
            )}

            <div className='add-product-label-name'>Limit Price: <div class="tooltip-container" tabindex="0">
              <div class="tooltip-circle">?</div>
              <div class="tooltip-text">The Limit Price is the price threshold you set for a product. When the product's price drops below this limit, you'll receive a notification, alerting you that it's a good time to purchase.</div>
            </div></div>
            <label className="add-product-label">
              <input
                className="add-product-input"
                type="number"
                value={limitPrice}
                onChange={(e) => setLimitPrice(e.target.value)}
                required
              />
            </label>
            <div className='add-product-information'></div>
            <div className='add-product-add-button-div'>
              <button className="add-product-button" type="submit">Add</button>
            </div>
          </form>
        </div>
        {showPopup && (
          <Popup
            message={popupMessage}
            onClose={handleClosePopup}
            handleClickOutside={handleClosePopup}
          />
        )}
        {loading && (
          <AddProductLoadingSpinner />
        )}
      </div>
    </div>
    </>
  );
};

export default AddProduct;
