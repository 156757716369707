import './UserProfile.css';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/authContext/AuthContext';
import SettingContext from '../../context/userSettingContext/SettingContext';
import { useNavigate } from 'react-router-dom';
import useAxiosInstance from '../../configs/useAxiosInstance';
import RighArrow from '../../resources/right-arrow.png';
import BackButtonHeader from '../backButtonHeader/BackButtonHeader';
import AnimatedToggle from '../../subcomponents/animatedToggle/AnimatedToggle';
import Popup from '../popupbox/Popup';


const DEBOUNCE_DELAY = 500;

const UserProfile = () => {
  const axiosInstance = useAxiosInstance();
  const navigate = useNavigate();
  const { userInfo } = useContext(AuthContext);
  const { userSetting, updateUserSetting } = useContext(SettingContext);
  const [showPopup, setShowPopup] = useState(false);
  const [message, setMessage] = useState("");

  const [emailNotification, setEmailNotification] = useState(userSetting.emailNotification);
  const [telegramNotification, setTelegramNotification] = useState(userSetting.telegramNotification);

  const formatDate = (dateTime) => {
    return new Date(dateTime).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  const [emailTogglePending, setEmailTogglePending] = useState(false);
  const [telegramTogglePending, setTelegramTogglePending] = useState(false);

  useEffect(() => {
    if (!emailTogglePending) return;

    const handler = setTimeout(() => {
      handleToggleSetting("EMAIL_NOTIFICATION", emailNotification)
        .then(success => {
          if (!success) {
            const revertedState = !emailNotification;
            setEmailNotification(revertedState);
            updateUserSetting(telegramNotification, revertedState, userSetting.telegramBotChatId);
          }
        });
      setEmailTogglePending(false);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(handler);
  }, [emailNotification, emailTogglePending]);

  useEffect(() => {
    if (!telegramTogglePending) return;

    const handler = setTimeout(() => {
      handleToggleSetting("TELEGRAM_NOTIFICATION", telegramNotification)
        .then(success => {
          if (!success) {
            const revertedState = !telegramNotification;
            setTelegramNotification(revertedState);
            updateUserSetting(revertedState, emailNotification, userSetting.telegramBotChatId);
          }
        });
      setTelegramTogglePending(false);
    }, DEBOUNCE_DELAY);

    return () => clearTimeout(handler);
  }, [telegramNotification, telegramTogglePending]);

  const handleToggleSetting = async (settingName, settingValue, setRevertState, revertValue) => {
    const switchSettingRequestDto = {
      userId: userInfo.userId,
      settingName,
      settingValue
    };

    try {
      const response = await axiosInstance.post(`api/usersetting/switch`, switchSettingRequestDto);
      return response.data.data === true;
    } catch (error) {
      console.error('Error switching the setting:', error);
      // Immediately revert the state on error
      setRevertState(revertValue);
      updateUserSetting(settingName === 'TELEGRAM_NOTIFICATION' ? revertValue : emailNotification,
        settingName === 'EMAIL_NOTIFICATION' ? revertValue : telegramNotification,
        userSetting.telegramBotChatId);
      return false;
    }
  };

  const handleEmailToggle = () => {
    const newEmailNotificationState = !emailNotification;
    setEmailNotification(newEmailNotificationState);
    updateUserSetting(telegramNotification, newEmailNotificationState, userSetting.telegramBotChatId);

    // Optimistic update, revert immediately on error
    handleToggleSetting("EMAIL_NOTIFICATION", newEmailNotificationState, setEmailNotification, !newEmailNotificationState);
  };

  const handleTelegramToggle = () => {
    const newTelegramNotificationState = !telegramNotification;
    setTelegramNotification(newTelegramNotificationState);
    updateUserSetting(newTelegramNotificationState, emailNotification, userSetting.telegramBotChatId);

    // Optimistic update, revert immediately on error
    handleToggleSetting("TELEGRAM_NOTIFICATION", newTelegramNotificationState, setTelegramNotification, !newTelegramNotificationState);
  };


  const handleTelegramToggleClick = (e) => {
    if (userSetting.telegramBotChatId == null || userSetting.telegramBotChatId <= 0) {
      e.preventDefault();
      setMessage(`
      You haven't connected with Bargain Bell's Telegram bot yet!<br/><br/>
      To enable Telegram notifications, please follow these steps:<br/><br/>
      1. <strong>Connect with the Bargain Bell bot:</strong> Log in to the bot using your registered email and password.<br/>
      2. <strong>Enable notifications:</strong> Once you're successfully logged in, Bargain Bell will send you important updates and price alerts directly to your Telegram account.<br/><br/>
      <strong>To connect, click on the link below to start chatting with our bot:</strong><br/><br/>
      <a href="https://t.me/bargainbell_bot">https://t.me/bargainbell_bot</a><br/><br/>
      After you log in, your Telegram notifications will be automatically enabled.
    `);

      setShowPopup(true);
      return;
    }
    handleTelegramToggle();
  };


  return (
    <>
      <BackButtonHeader />
      <div id="profile-page">

        <div className='profile-info-container'>
          <div className='profile-subheading'>Personal Info</div>

          <button className='profile-info-button' onClick={() => { navigate('/change-email') }}>
            <div className='profile-info'>
              <div className='profile-info-label'>Email </div>
              <div className='profile-info-value'>{userInfo.email}</div>
            </div>
            <div className='profile-info-change-icon'>
              <img src={RighArrow} />
            </div>
          </button>

          <hr />

          <button className='profile-info-button' onClick={() => { navigate('/change-name') }}>
            <div className='profile-info'>
              <div className='profile-info-label'>Name </div>
              <div className='profile-info-value'>{userInfo.firstName} {userInfo.lastName}</div>
            </div>
            <div className='profile-info-change-icon'>
              <img src={RighArrow} />
            </div>
          </button>

          <hr />

          <button className='profile-info-button' onClick={() => { navigate('/change-username') }}>
            <div className='profile-info'>
              <div className='profile-info-label'> Userame </div>
              <div className='profile-info-value'>{userInfo.username}</div>
            </div>
            <div className='profile-info-change-icon'>
              <img src={RighArrow} />
            </div>
          </button>

          <hr />

          <button className='profile-info-button'>
            <div className='profile-info'>
              <div className='profile-info-label'>Mobile Number </div>
              <div className='profile-info-value'>{userInfo.mobileNumber || `-`}</div>
            </div>
            <div className='profile-info-change-icon'>
              <img src={RighArrow} />
            </div>
          </button>

          <hr />

          <div className='profile-info-button'>
            <div className='profile-info'>
              <div className='profile-info-label'>Registered on</div>
              <div className='profile-info-value'>{formatDate(userInfo.accountCreatedAt)}</div>
            </div>
            <div className='profile-info-change-icon'>
              {/* <img src={RighArrow} /> */}
            </div>
          </div>

          <hr />

          <button className='profile-info-button' onClick={() => { navigate('/change-password') }}>
            <div className='profile-info'>
              Change password
            </div>
            <div className='profile-info-change-icon'>
              <img src={RighArrow} />
            </div>
          </button>

        </div>

        <div className='profile-switch-setting-container'>
          <div className='profile-subheading'>Setting</div>

          <button
            className='profile-switch-setting-div'
            onClick={handleEmailToggle}
          >
            <div className='profile-switch-setting-label'>Email notification</div>
            <div className='profile-switch-setting-button'>
              <AnimatedToggle isChecked={emailNotification} />
            </div>
          </button>

          <hr />

          <button
            className='profile-switch-setting-div'
            onClick={handleTelegramToggleClick}
          >
            <div className='profile-switch-setting-label'>Telegram notification</div>
            <div className='profile-switch-setting-button'>
              <AnimatedToggle isChecked={telegramNotification} />
            </div>
          </button>

        </div>


        <div className='profile-account-privacy-container'>
          <div className='profile-subheading'>Account Privacy</div>
          <button className='profile-info-button' onClick={() => { navigate('/delete-account') }}>
            <div className='profile-info'>
              Delete Account
            </div>
            <div className='profile-info-change-icon'>
              <img src={RighArrow} />
            </div>
          </button>
        </div>

      </div>
      {showPopup &&
        <Popup
          message={message}
          onClose={() => { setShowPopup(false) }}
          handleClickOutside={() => { setShowPopup(false) }}
        />
      }
    </>
  );
};

export default UserProfile;
