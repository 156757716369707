import React, { useState, useEffect } from 'react';
import './TextWriteEraseAnimation.css';

const TextWriteEraseAnimation = () => {
  // Set the texts you want to display here
  const texts = ["Amazon", "Flipkart", "Myntra"];
  const [textIndex, setTextIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [displayText, setDisplayText] = useState("");
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const typingSpeed = 150;
    const deletingSpeed = 100;
    const currentText = texts[textIndex];

    if (isDeleting) {
      if (charIndex > 0) {
        setTimeout(() => {
          setDisplayText(currentText.substring(0, charIndex - 1));
          setCharIndex(charIndex - 1);
        }, deletingSpeed);
      } else {
        setIsDeleting(false);
        setTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }
    } else {
      if (charIndex < currentText.length) {
        setTimeout(() => {
          setDisplayText(currentText.substring(0, charIndex + 1));
          setCharIndex(charIndex + 1);
        }, typingSpeed);
      } else {
        setTimeout(() => {
          setIsDeleting(true);
        }, 1000);
      }
    }
  }, [charIndex, isDeleting, textIndex, texts]);

  return (
    <div className="text-animation-container">
      <span className="animated-text">{displayText}</span>
      <span className="cursor"></span>
    </div>
  );
};

export default TextWriteEraseAnimation;
