import './OverlayLoadingSpinner1.css'

function OverlayLoadinSpinner1() {
    return (
        <div className='overlay-loading-spinner-overlay'>
          <div className='overlay-loading-spinner'></div>
        </div>
    );
}

export default OverlayLoadinSpinner1;