import './App.css';
import React from 'react';
import { Outlet } from 'react-router-dom';
import AuthDataContext from './context/authContext/AuthDataContext';
import SettingDataContext from './context/userSettingContext/SettingDataContext';

function App() {
  return (
    <SettingDataContext>
    <AuthDataContext>
        <Outlet />
    </AuthDataContext>
    </SettingDataContext>
  );
}

export default App;
