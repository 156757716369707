import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './ChangeName.css';
import useAxiosInstance from '../../../configs/useAxiosInstance';
import AuthContext from "../../../context/authContext/AuthContext";
import LoadingSpinner2 from "../../loadingAnimation/loading-spinner-2/LoadingSpinner2";
import BackButtonHeader from "../../backButtonHeader/BackButtonHeader";
import ScreenBlinkEffect from "../../popupbox/screenBlinkEffect/ScreenBlinkEffect";

function ChangeName() {
    const [showBlinkEffect, setShowBlinkEffect] = useState(true);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('Hii');
    const [editedUserInfo, setEditedUserInfo] = useState({});
    const [hasChanges, setHasChanges] = useState(false);
    const navigate = useNavigate();

    const { userInfo, updateUserInfo } = useContext(AuthContext);
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        setEditedUserInfo({
            firstName: userInfo.firstName,
            lastName: userInfo.lastName
        });
    }, [userInfo]);

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const userDetailDto = {
                firstName: editedUserInfo.firstName || userInfo.firstName,
                lastName: editedUserInfo.lastName || userInfo.lastName,
                mobileNumber: userInfo.mobileNumber,
                username: userInfo.username,
                email: userInfo.email,
            };

            const response = await axiosInstance.post(`api/user/update/user-info`, userDetailDto);
            console.log(response.data.data === true);

            if (response.data.data === true) {
                setMessage('User information updated successfully!');
                updateUserInfo(
                    userInfo.userId,
                    editedUserInfo.firstName || userInfo.firstName,
                    editedUserInfo.lastName || userInfo.lastName,
                    userInfo.email,
                    userInfo.username,
                    userInfo.mobileNumber,
                    userInfo.isLoggedIn,
                    userInfo.accountCreatedAt,
                    userInfo.token
                );
                setHasChanges(false);
            } else {
                setMessage('Failed to update user information');
            }
        } catch (error) {
            console.error('Error updating user data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (fieldName, newValue) => {
        setEditedUserInfo((prevInfo) => ({
            ...prevInfo,
            [fieldName]: newValue,
        }));

        if (
            newValue !== userInfo[fieldName]
        ) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    };

    const isSaveButtonDisabled = loading || !hasChanges;

    return (
        <>
            <BackButtonHeader />
            <div id="change-name-page">
                <div className="change-name-page-container">
                    <form onSubmit={handleSaveChanges} className="change-name-form">
                        <label>First name
                        </label>
                        <input
                            type='text'
                            value={editedUserInfo.firstName || ''}
                            onChange={(e) => handleInputChange('firstName', e.target.value)}
                        />
                        <label>
                            Last Name
                        </label>
                        <input
                            type='text'
                            value={editedUserInfo.lastName || ''}
                            onChange={(e) => handleInputChange('lastName', e.target.value)}
                        />
                        <button
                            type="submit"
                            disabled={isSaveButtonDisabled} // Disable when loading or no changes
                            className={`change-name-submit-button ${isSaveButtonDisabled ? 'change-name-submit-button-disabled' : 'change-name-submit-button-enabled'}`}
                        >
                            {loading ? <LoadingSpinner2 /> : 'Save'}
                        </button>
                    </form>
                </div>
            </div>
            {showBlinkEffect &&
                <ScreenBlinkEffect
                    message={message}
                />}
        </>
    );
};

export default ChangeName;
