import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthContext from '../../context/authContext/AuthContext';
import SettingContext from '../../context/userSettingContext/SettingContext';
import './Register.css';
import Popup from '../popupbox/Popup';
import VerifyEmail from './verifyEmail/VerifyEmail';
import useAxiosInstance from '../../configs/useAxiosInstance';
import OverlayLoadinSpinner1 from '../loadingAnimation/0verlay-loading-spinner-1/OverlayLoadingSpinner1';

const Register = () => {
    const axiosInstance = useAxiosInstance();
    const { updateUserInfo } = useContext(AuthContext);
    const { updateUserSetting } = useContext(SettingContext);
    const navigate = useNavigate();
    const [sentOTP, setSentOtp] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [usernameAvaibility, setUsernameAvaibility] = useState(false);
    const [showUsernameAvaibility, setShowUsernameAvaibility] = useState(false);
    const [message, setMessage] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [registrationSuccessful, setRegistrationSuccessful] = useState(false);
    const [passwordValidationState, setPasswordValidationState] = useState(true);

    const [passwordValidation, setPasswordValidation] = useState({
        hasUpperCase: false,
        hasLowerCase: false,
        hasDigit: false,
        hasSpecialChar: false
    });

    const [deviceInfo, setDeviceInfo] = useState({
        ipAddress: '',
        userAgent: navigator.userAgent,
        platform: navigator.platform,
        language: navigator.language,
        deviceType: '',
        osName: '',
        osVersion: '',
        browserName: '',
        browserVersion: '',
        deviceId: '',
        screenResolution: `${window.screen.width}x${window.screen.height}`,
        hardwareInfo: '',
        networkType: ''
    });

    useEffect(() => {
        const detectDeviceInfo = () => {
            const userAgent = navigator.userAgent;
            let deviceType = 'unknown';
            if (/mobile/i.test(userAgent)) {
                deviceType = 'mobile';
            } else if (/tablet/i.test(userAgent)) {
                deviceType = 'tablet';
            } else {
                deviceType = 'desktop';
            }
            setDeviceInfo(prev => ({
                ...prev,
                deviceType,
                osName: navigator.platform,
                browserName: userAgent.split(' ')[0]
            }));
        };

        detectDeviceInfo();
    }, []);

    const isValidPassword = (password) => {
        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasDigit = /\d/.test(password);
        const hasSpecialChar = /[!@#$%^&*().]/.test(password);

        setPasswordValidation({
            hasUpperCase,
            hasLowerCase,
            hasDigit,
            hasSpecialChar
        });

        setPasswordValidationState(hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar);

        return hasUpperCase && hasLowerCase && hasDigit && hasSpecialChar;
    };

    let usernameCheckTimeout; // declare this outside the component if possible, or within the component scope

    const onUsernameChange = (e) => {
        const newUsername = e.target.value;
        setUsername(newUsername);

        // Clear the previous timeout if the user is still typing
        clearTimeout(usernameCheckTimeout);

        if (newUsername.trim() === '') {
            setShowUsernameAvaibility(false);
            setUsernameAvaibility(null);
            return;
        }

        // Set a new timeout to wait 500ms before checking username availability
        usernameCheckTimeout = setTimeout(async () => {
            setShowUsernameAvaibility(true);
            try {
                const response = await axiosInstance.get(`api/register/check/username/${newUsername}`);
                setUsernameAvaibility(response.data);
            } catch (error) {
                console.error("Error checking username availability:", error);
            }
        }, 500); // 500ms delay before making the API call
    };


    const handleSendOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!isValidPassword(password)) {
            setMessage("Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.");
            setShowPopup(true);
            setLoading(false);
            return;
        }
        const requestPayload = {
            email,
            ...deviceInfo
        };
        if (email.trim() === '') {
            alert("Please enter email");
            return;
        }
        if (username.trim() === '') {
            alert("Please enter username");
            return;
        }
        if (password.trim() === '') {
            alert("Please enter Password");
            return;
        }
        if (password !== confirmPassword) {
            alert("Passwords are not matching!");
            return;
        }

        try {
            const response = await axiosInstance.post('api/register/send-otp/email', requestPayload);
            console.log(response.data.data);

            if (response.data.data === "SUCCESSFUL") {
                setSentOtp(true);
            } else if (response.data.data === "INVALID_INPUT") {
                setShowPopup(true);
                setMessage("Incorrect email!");
            } else if (response.data.data === "TOO_MANY_REQUESTS") {
                setShowPopup(true);
                setMessage("OTP limit reached, please try after sometime!");
            } else {
                setShowPopup(true);
                setMessage("Failed to send OTP, please try after sometime!");
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!isValidPassword(password)) {
            setMessage("Password must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.");
            setShowPopup(true);
            setLoading(false);
            return;
        }
        const userDetailRegistrationDto = {
            firstName,
            lastName,
            email,
            username,
            password,
            mobileNumber,
            otp
        };
        try {
            const response = await axiosInstance.post('api/register/register', userDetailRegistrationDto);

            if (response.data.data === "SUCCESSFUL") {
                setRegistrationSuccessful(true);
            } else if (response.data.data === "WRONG_OTP") {
                setShowPopup(true);
                setMessage("Incorrect OTP!");
            } else if (response.data.data === "INVALID_INPUT") {
                setShowPopup(true);
                setMessage("Incorrect input data, please check!");
            } else if (response.data.data === "TOO_MANY_REQUESTS") {
                setShowPopup(true);
                setMessage("OTP limit reached, please try after sometime!");
            } else {
                setShowPopup(true);
                setMessage("Failed to send OTP, please try after sometime!");
            }
        } catch (error) {
            console.error(error);
            setMessage("Failed to register, please try after sometime");
            setShowPopup(true);
        } finally {
            setLoading(false);
        }
    };

    const signIn = async () => {
        const LoginRequestDto = {
            email,
            password
        };

        try {
            const response = await axiosInstance.post('api/auth/login', LoginRequestDto);

            if (response.status === 200) {
                const responseData = response.data;
                updateUserInfo(responseData.data.userId, responseData.data.firstName, responseData.data.lastName, responseData.data.email, responseData.data.username, responseData.data.mobileNumber, true, responseData.data.accountCreatedAt, responseData.data.token);
                updateUserSetting(
                    responseData.data.telegramBotNotificationEnabled,
                    responseData.data.emailNotificationsEnabled,
                    responseData.data.telegramBotChatId
                )
                navigate('/');
            } else {
                setMessage("Failed auto login, please login manually");
                setShowPopup(true);
            }
        } catch (error) {
            setMessage("Error logging in, please login manually");
            setShowPopup(true);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        if (password === e.target.value) {
            setPasswordsMatch(true);
        } else {
            setPasswordsMatch(false);
        }
    };

    const handlePasswordChange = (e) => {
        const password = e.target.value;
        setPassword(password);
        isValidPassword(password); // Perform real-time validation
    };


    return (
        <div className='register-page'>
            <div className='register-page-container'>

                <div className='register-header-container'>
                    <h1>Register</h1>
                </div>
                <form onSubmit={handleSendOtp} className='register-form'>
                    <div className='register-input-grid'>
                        <input
                            placeholder="First name"
                            type="text"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            required
                        />
                        <input
                            placeholder="Last name"
                            type='text'
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            required
                        />
                    </div>
                    <div className='register-input-grid'>
                        <input
                            placeholder="Email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <input
                            placeholder="Username"
                            type='text'
                            value={username}
                            onChange={onUsernameChange}
                            required
                        />
                    </div>
                    {showUsernameAvaibility && (
                        <div className="register-username-availability">
                            {usernameAvaibility ? (
                                <p>Great! Username is available.</p>
                            ) : (
                                <p>This username is already taken.</p>
                            )}
                        </div>
                    )}
                    <div className='register-input-grid'>
                        <input
                            type='password'
                            placeholder="Password"
                            value={password}
                            onChange={handlePasswordChange}
                            required
                        />
                        <input
                            type='password'
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                        />
                        {!passwordsMatch && (
                            <div className="password-match-status">Passwords do not match.</div>
                        )}
                    </div>
                    {!passwordValidationState && (
                        <div className="password-validation-status">Password must have at least 1 uppercase letter, 1 lowercase letter, 1 digit, and 1 special character.</div>
                    )}
                    <button className="register-submit-button" type="submit" disabled={loading}>
                        {loading ? "Sending OTP..." : "Submit"}
                    </button>
                </form>

                <button className='register-home-button' onClick={() => { navigate('/') }} disabled={loading}>
                    Home
                </button>

            </div>

            {sentOTP && <VerifyEmail
                handleVerifyOtp={handleVerifyOtp}
                setOtp={setOtp}
                otp={otp}
                email={email}
                loading={loading}
                setSentOtpFalse={() => { setSentOtp(false) }}
            />}
            {showPopup && (
                <Popup
                    message={message}
                    onClose={() => { setShowPopup(false) }}
                    handleClickOutside={() => setShowPopup(false)}
                />
            )}
            {registrationSuccessful && (
                <Popup
                    message={"Registration successful!"}
                    onClose={signIn}
                    handleClickOutside={signIn}
                />
            )}
            {loading && <OverlayLoadinSpinner1 />}
        </div>
    );
};

export default Register;