import React, { useState, useEffect } from "react";
import SettingContext from "./SettingContext";

const SettingDataContext = (props) => {

    const storedUserSetting = localStorage.getItem('bbUserSetting');

    const defaultUserSetting = storedUserSetting ? JSON.parse(storedUserSetting) : {
        "telegramNotification": false,
        "emailNotification": false,
        "telegramBotChatId":""
    };

    const [userSetting, setUserSetting] = useState(defaultUserSetting);

    useEffect(() => {
        localStorage.setItem('bbUserSetting', JSON.stringify(userSetting));
    }, [userSetting]);

    const updateUserSetting = (telegramNotification, emailNotification, telegramBotChatId) => {
        const newUserSetting = {
            ...userSetting,
            telegramNotification: telegramNotification,
            emailNotification: emailNotification,
            telegramBotChatId: telegramBotChatId
        };
        setUserSetting(newUserSetting);
        localStorage.setItem('bbUserSetting', JSON.stringify(newUserSetting));
    };

    return (
        <SettingContext.Provider value={{ userSetting, updateUserSetting }}>
            {props.children}
        </SettingContext.Provider>
    );
};

export default SettingDataContext;

