import './AddProductLoadingSpinner.css'

function AddProductLoadingSpinner() {
  return (
    <div className='add-product-loading-spinner-overlay'>
      <div className='add-product-loading-spinner'></div>
      <div className='add-product-loading-spinner-message'>Fetching price..</div>
    </div>
  );
}

export default AddProductLoadingSpinner;