import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../context/authContext/AuthContext';
import SettingContext from '../../context/userSettingContext/SettingContext';
import useAxiosInstance from '../../configs/useAxiosInstance';
import './signIn.css';
import Popup from '../popupbox/Popup';
import Logo from '../../resources/full-logo-navy-blue.png';
import LoadingSpinner2 from '../loadingAnimation/loading-spinner-2/LoadingSpinner2';

const SignIn = () => {
  const { updateUserInfo } = useContext(AuthContext);
  const { updateUserSetting } = useContext(SettingContext);
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const axiosInstance = useAxiosInstance();

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateInputs = () => {
    // Email validation
    if (email.trim() === '') {
      setMessage("Please enter your email.");
      setShowPopup(true);
      return false;
    }
    if (!emailRegex.test(email)) {
      setMessage("Please enter a valid email address.");
      setShowPopup(true);
      return false;
    }

    // Password validation
    if (password.trim() === '') {
      setMessage("Please enter your password.");
      setShowPopup(true);
      return false;
    }

    if (password.length < 8) {
      setMessage("Password must be at least 8 characters long.");
      setShowPopup(true);
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    setLoading(true);

    const loginRequestDto = {
      email: email.trim(),
      password: password.trim(),
    };

    try {
      const response = await axiosInstance.post('api/auth/login', loginRequestDto);

      if (response.status === 200) {
        const responseData = response.data;
        if (responseData.status === 200) {
          updateUserInfo(
            responseData.data.userId,
            responseData.data.firstName,
            responseData.data.lastName,
            responseData.data.email,
            responseData.data.username,
            responseData.data.mobileNumber,
            true,
            responseData.data.accountCreatedAt,
            responseData.data.token
          );

          updateUserSetting(
            responseData.data.telegramBotNotificationEnabled,
            responseData.data.emailNotificationsEnabled,
            responseData.data.telegramBotChatId
          )
          navigate('/');
        } else if (responseData.status === 401) {
          setMessage("Wrong Email or Password");
          setShowPopup(true);
        }
      }
      else {
        setMessage("Login failed, please try again later.");
        setShowPopup(true);
      }
    } catch (error) {
      console.error('Error during login request:', error);
      setMessage("Error logging in. Pleas try after sometime.");
      setShowPopup(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='signin-page'>
      <div className='sigin-page-container'>
        <img alt='logo' src={Logo} className='signin-logo' />
        <form className='signin-form' onSubmit={handleSubmit}>
          <input
            placeholder="Email address"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
            required
          />
          <input
            type='password'
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
            required
          />
          <button
            className='signin-signin-button'
            type="submit"
            disabled={loading}
          >
            {loading ? <LoadingSpinner2 /> : 'Login'}
          </button>
          <button className='sigin-forgot-password-button' onClick={() => { navigate('/forgot-password') }} disabled={loading}>
            Forgotten Password?
          </button>
        </form>
        <button className='signin-register-button' onClick={() => { navigate('/register') }} disabled={loading}>
          Create new account
        </button>
      </div>
      <button className='signin-home-button' onClick={() => { navigate('/') }} disabled={loading}>
        Home
      </button>

      {showPopup && (
        <Popup
          message={message}
          onClose={() => setShowPopup(false)}
          handleClickOutside={() => setShowPopup(false)}
        />
      )}
    </div>
  );
}

export default SignIn;
