import React, { useContext, useState, useEffect, useRef } from 'react';
import AuthContext from '../../context/authContext/AuthContext';
import Navbar from './navbar/Navbar';
import Sidebar from './sidebar/Sidebar';
import { useLogout } from '../../utils/authUtils';

function Header() {
    const { userInfo } = useContext(AuthContext);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [touchMoved, setTouchMoved] = useState(false);
    const startX = useRef(0);

    const openSidebar = () => {
        setIsSidebarOpen(true);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    const { handleLogout } = useLogout();  // Call the useLogout hook

    // Handle touch start
    const handleTouchStart = (e) => {
        startX.current = e.touches[0].clientX; // Get the initial touch position
        setTouchMoved(false);
    };

    const handleTouchMove = (e) => {
        const touchX = e.touches[0].clientX;
        const windowWidth = window.innerWidth; // Get the window width
    
        // Calculate positions from the right edge
        const positionFromRight = windowWidth - touchX;
    
        if ((startX.current - touchX > 50)  && positionFromRight <= 150) {
            openSidebar();
            setTouchMoved(true);
        } else if (touchX - startX.current > 50 && isSidebarOpen) {
            closeSidebar();
            setTouchMoved(true);
        }
    };
    
    // Handle touch end
    const handleTouchEnd = () => {
        setTouchMoved(false);
    };

    // Add touch event listeners on the entire document
    useEffect(() => {
        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [isSidebarOpen]);

    return (
        <>
            <Navbar isSidebarOpen={isSidebarOpen} openSidebar={openSidebar} closeSidebar={closeSidebar} handleLogout={handleLogout} />
            <Sidebar isSidebarOpen={isSidebarOpen} closeSidebar={closeSidebar} userInfo={userInfo} handleLogout={handleLogout} />
        </>
    );
}

export default Header;
