import React from 'react';
import { useNavigate } from 'react-router-dom';
import './footer.css';
import InstagramIcon from '../../resources/instagram_icon_x64.png';
import LinkedInIcon from '../../resources/linkedin_icon_x64.png';
import Github from '../../resources/borderless-github-icon-white.png';

const Footer = () => {

  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="footer-about-developer-button" onClick={() => { navigate('/') }} >
        Connect with us
      </div>
      <div className='footer-icon-div'>
        <a href='https://www.instagram.com/yash_carpenter' target="_blank" rel="noopener noreferrer">
          <img src={InstagramIcon} alt="Instagram Icon" />
        </a>
        <a href='https://www.linkedin.com/in/yashcarpenter' target="_blank" rel="noopener noreferrer">
          <img src={LinkedInIcon} alt="LinkedIn Icon" />
        </a>
        <a href='https://www.github.com/yashcarpenter' target="_blank" rel="noopener noreferrer">
          <img src={Github} alt="Github Icon" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
