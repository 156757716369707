import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Sidebar.css';
import hideIcon from '../../../resources/close-icon.png';
import homeIcon from '../../../resources/home-icon.png';
import watchlistIcon from '../../../resources/watchlist-icon.png';
import addProductIcon from '../../../resources/add-icon.png';
import profileIcon from '../../../resources/profile-icon.png';
import logoutIcon from '../../../resources/logout-icon.png';

function Sidebar({ isSidebarOpen, closeSidebar, userInfo, handleLogout }) {
    const navigate = useNavigate();

    return (
        <>
            {isSidebarOpen && (
                <div className="sidebar-backdrop" onClick={closeSidebar}></div>
            )}
            
            <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                <div className="sidebar-close-sidebar-button-div">
                    <img src={hideIcon} alt="Close Sidebar" onClick={closeSidebar} />
                </div>

                <div className="sidebar-user-info-div">
                    <div className="sidebar-user-info-name">{userInfo.firstName} {userInfo.lastName}</div>
                    <div className="sidebar-user-info-email">{userInfo.email}</div>
                </div>

                <div className="sidebar-links-container">
                    <button className="sidebar-links-div" onClick={() => { navigate('/'); closeSidebar(); }}>
                        <div>Home</div>
                        <img src={homeIcon} alt="Home" />
                    </button>
                    <button className="sidebar-links-div" onClick={() => { navigate('/addproduct'); closeSidebar(); }}>
                        <div>Add Product</div>
                        <img src={addProductIcon} alt="Add Product" />
                    </button>
                    <button className="sidebar-links-div" onClick={() => { navigate('/watchlist'); closeSidebar(); }}>
                        <div>Watchlist</div>
                        <img src={watchlistIcon} alt="Watchlist" />
                    </button>
                    <button className="sidebar-links-div" onClick={() => { navigate('/userprofile'); closeSidebar(); }}>
                        <div>Profile</div>
                        <img src={profileIcon} alt="Profile" id='profile-icon'/>
                    </button>
                </div>

                <button className="sidebar-logout-button-div" onClick={() => handleLogout(closeSidebar)}>
                    <div>Logout</div>
                    <img src={logoutIcon} alt="Logout" />
                </button>
            </div>
        </>
    );
}

export default Sidebar;
