import './WelcomePage.css';
import React, { useContext, useEffect } from 'react';
import AuthContext from '../../context/authContext/AuthContext';
import Header from './welcomepageheader/WelcomePageHeader';
import PlatformCarousel from '../../subcomponents/platformCarousel/PlatformCarousel';
import UserGuideTimeline from '../../subcomponents/userGuideTimeline/UserGuideTimeline';
// import Logo from '../../resources/logo.png';
import Logo from '../../resources/full-logo-navy-blue.png';
import Footer from '../footer/Footer';
import TextWriteEraseAnimation from '../../subcomponents/textWriteEraseAnimation/TextWriteEraseAnimation';
import TryNowCarousel from '../../subcomponents/tryNowCarousel/TryNowCarousel';
import { useNavigate } from 'react-router-dom';
import WatchlistIcon from '../../resources/welcomePageIcons/watchlist-icon-white-2.png';
import AddProductIcon from '../../resources/welcomePageIcons/add-to-watchlist-icon-white.png';
import RighArrow from '../../resources/welcomePageIcons/simple-right-arrow-icon-white.png';
import LoginIcon from '../../resources/welcomePageIcons/login-icon-white.png';
import RegisterIcon from '../../resources/welcomePageIcons/create-account-white-icon.png';

function WelcomePage2() {

  const navigate = useNavigate();

  useEffect(() => {
    document.body.style.backgroundColor = '#030a15';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, []);

  const { userInfo } = useContext(AuthContext);

  return (
    <>
      {userInfo.isLoggedIn && <Header />}
      <div className='welcome-page'>

        <div className='welcome-page-platform-name-animation-title'>  {/* 1 */}
          <div className='title'>Track Prices of your favourite products on </div>
          <div className='animation-text'><TextWriteEraseAnimation /></div>
        </div>
        <div className='welcome-page-logo-div'> {/* 2 */}
          <img src={Logo} className='welcome-page-logo' alt='Bargain Bell Logo' />
        </div>
        <div className="welcomepage-short-description-div"> {/* 3 */}
          <p>Discover a smarter way to shop online with Bargain Bell. Let us track prices for you, saving time and ensuring you never overpay again.</p>
        </div>

        {userInfo.isLoggedIn ? (
          <div className='welcome-page-navigation-buttons'>
            <button onClick={() => { navigate('/watchlist') }}>
              <img src={WatchlistIcon} /> My watchlist <img src={RighArrow} className='welcome-page-button-right-arrow' />
            </button>
            <button onClick={() => { navigate('/addproduct') }}>
              <img src={AddProductIcon} /> Add product <img src={RighArrow} className='welcome-page-button-right-arrow' />
            </button>
          </div>
        ) : (
          <div className='welcome-page-auth-buttons'> {/* 4.1-Left */}
            <button onClick={() => { navigate('/signin') }} >
              <img src={LoginIcon} /> Login <img src={RighArrow} className='welcome-page-button-right-arrow' />
            </button>
            <button onClick={() => { navigate('/register')}}  className='welcome-page-register-button' >
              <img src={RegisterIcon} /> Create account <img src={RighArrow} className='welcome-page-button-right-arrow' />
            </button>
          </div>
        )}

        <div className='welcome-page-platform-carousel-div'> {/* 4.2-Left */}
          <h6 className='welcome-page-grid-sub-heading'>Number of products in our track-list</h6>
          <div className='welcome-page-platform-carousel'><PlatformCarousel /></div>
        </div>

        <div className='welcome-page-user-guide-div'> {/* 4-right */}
          <h6 className='welcome-page-grid-sub-heading'>Steps to add product to track it's price</h6>
          <div className='welcome-page-user-guide'><UserGuideTimeline /></div>
          <div className='welcome-page-user-guide-trynow-div'><TryNowCarousel /></div>
        </div>
        <div className="welcomepage-description-div"> {/* 5 */}
          <p>At Bargain Bell, our mission is to help you save both money and the valuable time you spend checking prices repeatedly. We believe that everyone deserves access to the best deals and reasonably priced products. That’s why we offer seamless price monitoring across platforms like Amazon, Flipkart, and Myntra. With real-time alerts for price drops, you can simplify your shopping experience with our user-friendly platform. Join Bargain Bell today and take the first step toward smarter, budget-friendly shopping!</p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default WelcomePage2;
