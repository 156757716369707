import React, { useEffect, useState } from 'react';
import './ProductDetail.css';
import PriceChart from '../priceChart/PriceChart';
// import PriceChart from '../priceChart/PriceChart2';
import ConfirmationPopup from '../popupbox/confirmationPopup/ConfirmationPopup';
import LoadingSpinner1 from '../loadingAnimation/loading-spinner-1/LoadingSpinner1';
import { Container } from 'react-bootstrap';

const ProductDetail = ({
    selectedProduct,
    editedProductInfo,
    editMode,
    showDetails,
    handleCloseModal,
    handleInputChange,
    handleSaveChanges,
    toggleEditMode,
    handleDeleteProduct,
    loadingSaveChanges
}) => {
    const [showPopup, setShowPopup] = useState(false);

    const handleOpenEditMode = () => {
        toggleEditMode(); // Invoke the function to toggle edit mode
        window.history.pushState({ editModeOpened: true }, ''); // Push new state to history
    };

    const handleCloseEditMode = () => {
        toggleEditMode();
        if (window.history.state && window.history.state.editModeOpened) {
            window.history.back();
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && editMode) {
                handleCloseEditMode();
            }
        };

        const handlePopState = () => {
            if (editMode) {
                handleCloseEditMode();
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [editMode, handleCloseEditMode]);


    if (!showDetails || !selectedProduct) return null;



    const formatTime = (dateTime) => {
        return new Date(dateTime).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        });
    };

    const formatDate = (dateTime) => {
        return new Date(dateTime).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    const confirmDeleteProduct = () => {
        setShowPopup(true);
    };

    const handleConfirmDeleteProduct = (confirm) => {
        setShowPopup(false);
        if (confirm) {
            handleDeleteProduct(selectedProduct); // Call the delete function only if confirmed
        }
    };

    function handleOutsideClick(e) {
        const popupBox = document.querySelector('.product-details-content');
        if (popupBox && !popupBox.contains(e.target)) {
            handleCloseModal();
        }
    }

    return (
        <div className="product-details-page" onClick={handleOutsideClick}>
            <div className="product-details-content">

                <div className="product-details-header">
                    <h2>Product Details</h2>
                    <button className="product-details-close-button" onClick={handleCloseModal}>
                        &times;
                    </button>
                </div>

                <div id="product-details-table-div">
                    {loadingSaveChanges ? (<div className='product-detail-loading-spinner'><LoadingSpinner1 /> </div>) : (
                        <table className='product-details-table'>
                            <tbody>
                                <tr>
                                    <td>Product Name:</td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                type="text"
                                                value={editedProductInfo.productName || ''}
                                                onChange={(e) => handleInputChange('productName', e.target.value)}
                                            />
                                        ) : (
                                            <div>{selectedProduct.productName}</div>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Platform:</td>
                                    <td>
                                        <div>{selectedProduct.platform}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Added On:</td>
                                    <td>
                                        <div>{formatDate(selectedProduct.productAddedAt)}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Minimum Price:</td>
                                    <td>
                                        <div>
                                            &#8377; {selectedProduct.minPrice}
                                            <div className='product-details-table-date-div'>
                                                {formatDate(selectedProduct.lastMinPriceAt)}
                                                <div className='product-details-table-time-div'>
                                                    ({formatTime(selectedProduct.lastMinPriceAt)})
                                                </div>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Limit Price:</td>
                                    <td>
                                        {editMode ? (
                                            <input
                                                type="text"
                                                value={editedProductInfo.limitPrice || ''}
                                                onChange={(e) => handleInputChange('limitPrice', e.target.value)}
                                            />
                                        ) : (
                                            <div>&#8377; {selectedProduct.limitPrice}</div>
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Last Price:</td>
                                    <td>
                                        <div>
                                            &#8377; {selectedProduct.lastPrice}
                                            <div className='product-details-table-date-div'>
                                                {formatDate(selectedProduct.lastPriceAt)}
                                                <div className='product-details-table-time-div'>
                                                    ({formatTime(selectedProduct.lastPriceAt)})
                                                </div>
                                            </div>

                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Product Id:</td>
                                    <td>
                                        <div>{selectedProduct.productId}</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    )}


                    <div className="product-details-edit-save-button-div">
                        {editMode && <button onClick={handleSaveChanges}>Save Changes</button>}
                        <button onClick={editMode ? handleCloseEditMode : handleOpenEditMode}>
                            {editMode ? 'Cancel' : 'Update'}
                        </button>
                    </div>
                    <Container className='product-details-price-chart-container'>
                        <PriceChart
                            productId={selectedProduct.productId}
                            platform={selectedProduct.platform}
                        />
                    </Container>
                    <button className='product-details-remove-button' onClick={confirmDeleteProduct}>
                        Remove
                    </button>
                </div>
            </div>

            {showPopup && (
                <ConfirmationPopup
                    message="Are you sure you want to delete this product?"
                    onOkay={(confirm) => handleConfirmDeleteProduct(confirm)}
                    onClose={() => { setShowPopup(false) }}
                    firstButtonLabel="Remove"
                    secondButtonLabel="Cancel"
                    setShowPopup={setShowPopup}
                />
            )}
        </div>
    );
};

export default ProductDetail;
