import React, { useContext, useState } from "react";
import AuthContext from "../../context/authContext/AuthContext";
import './DeleteAccount.css';
import { useNavigate } from 'react-router-dom';
import Popup from "../popupbox/Popup";
import useAxiosInstance from '../../configs/useAxiosInstance';
import OverlayLoadinSpinner1 from "../loadingAnimation/0verlay-loading-spinner-1/OverlayLoadingSpinner1";
import BackButtonHeader from "../backButtonHeader/BackButtonHeader";

function DeleteAccount() {

    const { userInfo, updateUserInfo } = useContext(AuthContext);
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [deletedSuccessful, setDeletedSuccessful] = useState(false);

    const navigate = useNavigate();
    const axiosInstance = useAxiosInstance();

    const handleDeleteAccount = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (password.trim() === '') {
            setError("Please enter Password");
            setShowPopup(true);
            setLoading(false);
            return;
        }

        try {
            const response = await axiosInstance.post(`/api/user/delete/account`, null, {
                params: {
                    userId: userInfo.userId,
                    password: password,
                },
            });

            const responseData = response.data;

            if (response.status === 200 && responseData.data === "SUCCESSFUL") {
                updateUserInfo(null, null, null, null, null, null, false, null, null);
                setDeletedSuccessful(true);

                setTimeout(() => {
                    // navigate('/');
                }, 2000);
            } else {
                setError(responseData.message || 'Failed to delete account');
                setShowPopup(true);
            }
        } catch (error) {
            console.error('Error deleting account:', error);
            setError('Error deleting account');
            setShowPopup(true);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <BackButtonHeader />
            <div id="delete-account-blur-background"></div>
            <div id="delete-account-overlay">
                <div id="delete-account-box">
                    <form onSubmit={handleDeleteAccount}>
                        <div id="delete-account-box-message">Are you sure you want to delete the account?</div>
                        <div id="delete-account-box-password-input-div">
                            Please Confirm Password
                            <input
                                type="password"
                                label="password"
                                required
                                value={password}
                                onChange={(e) => { setPassword(e.target.value) }}
                            />
                            <div id="delete-account-box-button-div">
                                <button
                                    className="delete-account-box-password-cancel-button"
                                    type="button"
                                    onClick={() => { navigate('/userprofile') }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="delete-account-box-password-confirm-button"
                                    type="submit"
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                {showPopup && (
                    <Popup
                        message={error}
                        onClose={() => setShowPopup(false)}
                        handleClickOutside={() => setShowPopup(false)}
                    />
                )}
                {deletedSuccessful && (
                    <Popup
                        message="Account deleted successfully!"
                        onClose={() => navigate('/')}
                        handleClickOutside={() => navigate('/')}
                    />
                )}
                {loading && <OverlayLoadinSpinner1 />}
            </div>
        </>
    );
}

export default DeleteAccount;
