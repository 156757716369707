import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AddProduct from './components/addProduct/AddProduct';
// import WelcomePage from './components/welcomePage/WelcomePage';
import WelcomePage from './components/welcomePage/WelcomePage';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
} from 'react-router-dom';
import ProductList from './components/productList/ProductList';
import UserProfile from './components/userProfile/UserProfile';
import SignIn from './components/signIn/SignIn';
import Register from './components/register/Register';
import AuthDataContext from './context/authContext/AuthDataContext';
import ProtectedRoute from './configs/ProtectedRoute';
import ForgotPassword from './components/forgotPassword/ForgotPassword';
import DeleteAccount from './components/deleteAccount/DeleteAccount';
import ChangePassword from './components/userProfile/changePassword/ChangePassword';
import ChangeEmail from './components/userProfile/changeEmail/ChangeEmail';
import ChangeName from './components/userProfile/changeName/ChangeName';
import ChangeUsername from './components/userProfile/changeUsername/ChangeUsername';

const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    children: [
      {
        path: '/signin',
        element: <SignIn />,
      },
      {
        path: '/addproduct',
        element: (
          <ProtectedRoute>
            <AddProduct />
          </ProtectedRoute>
        ),
      },
      {
        path: '',
        element: <WelcomePage />,
      },
      {
        path: '/watchlist',
        element: (
          <ProtectedRoute>
            <ProductList />
          </ProtectedRoute>
        ),
      },
      {
        path: '/userprofile',
        element: (
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        ),
      },
      {
        path: '/delete-account',
        element: (
          <ProtectedRoute>
            <DeleteAccount />
          </ProtectedRoute>
        ),
      },
      {
        path: '/change-email',
        element: (
          <ProtectedRoute>
            < ChangeEmail/>
          </ProtectedRoute>
        ),
      },
      {
        path: '/change-password',
        element: (
          <ProtectedRoute>
            <ChangePassword />
          </ProtectedRoute>
        ),
      },
      {
        path: '/change-name',
        element: (
          <ProtectedRoute>
            <ChangeName />
          </ProtectedRoute>
        ),
      },
      {
        path: '/change-username',
        element: (
          <ProtectedRoute>
            <ChangeUsername/>
          </ProtectedRoute>
        ),
      },
      {
        path: '/forgot-password',
        element: (
          <ForgotPassword />
        ),
      },
      {
        path: '/register',
        element: <Register/>,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthDataContext>
      <RouterProvider router={router} />
    </AuthDataContext>
);

reportWebVitals();
