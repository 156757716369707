import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/authContext/AuthContext';
import './ProductList.css';
import MyntraIcon from '../../resources/myntra-icon.png';
import AmazonIcon from '../../resources/amazon-icon.png';
import FlipkartIcon from '../../resources/flipkart-icon.png';
import ProductDetailOverlay from '../productDetails/ProductDetail.js';
import useAxiosInstance from '../../configs/useAxiosInstance.js';
import LoadingSpinner1 from '../loadingAnimation/loading-spinner-1/LoadingSpinner1.js';
import Header from '../header/Header.js';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [editedProductInfo, setEditedProductInfo] = useState({});
    const { userInfo } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [loadingSaveChanges, setLoadingSaveChanges] = useState(false);


    const axiosInstance = useAxiosInstance();

    const toggleEditMode = () => {
        setEditMode(!editMode);
    };

    const fetchProductList = async () => {
        setLoading(true);

        const productListKey = `productList_${userInfo.userId}`;
        const productListExpiryKey = `productListExpiry_${userInfo.userId}`;

        const storedProductList = localStorage.getItem(productListKey);
        const expiryTime = localStorage.getItem(productListExpiryKey);

        if (storedProductList && expiryTime && Date.now() < expiryTime) {
            setProducts(JSON.parse(storedProductList));
            setLoading(false);
            return;
        }

        try {
            const response = await axiosInstance.post(`/api/product/getuserproductlist/${userInfo.userId}`);
            const productList = response.data.data;
            setProducts(productList);

            const expiryTime = Date.now() + 10 * 60 * 1000; // 10 minutes in milliseconds
            localStorage.setItem(productListKey, JSON.stringify(productList));
            localStorage.setItem(productListExpiryKey, expiryTime);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch products on component mount
    useEffect(() => {
        fetchProductList();
    }, [userInfo.email, axiosInstance]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            const productListKey = `productList_${userInfo.userId}`;
            if (event.key === `productList_${userInfo.userId}_updated`) {
                fetchProductList();
            }
        };

        const handleKeyDown = (event) => {
            const keyActionMap = {
                'Escape': () => {
                    if (editMode) {
                        toggleEditMode();
                    } else if (showDetails) {
                        handleCloseModal();
                    }
                }
            };

            if (keyActionMap[event.key]) {
                keyActionMap[event.key]();
            }
        };

        const handlePopState = (event) => {
            if (showDetails) {
                handleCloseModal();
            }
        };

        window.addEventListener('storage', handleStorageChange);
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('popstate', handlePopState);
        };
    }, [showDetails, userInfo.userId, fetchProductList, editMode, toggleEditMode]);


    const handleDetailsClick = (product) => {
        setSelectedProduct(product);
        setEditedProductInfo(product);
        setShowDetails(true);
        window.history.pushState({ popupOpen: true }, '');
    };

    const handleCloseModal = () => {
        setShowDetails(false);
        setEditMode(false);
        if (window.history.state && window.history.state.popupOpen) {
            window.history.back();
        }
    };

    const handleInputChange = (fieldName, newValue) => {
        setEditedProductInfo((prevInfo) => ({
            ...prevInfo,
            [fieldName]: newValue,
        }));
    };

    const handleSaveChanges = async () => {
        setLoadingSaveChanges(true);
        try {
            const response = await axiosInstance.post('/api/product/updateinfo', {
                userId: userInfo.userId,
                platform: editedProductInfo.platform,
                productId: editedProductInfo.productId,
                newLimitPrice: editedProductInfo.limitPrice,
                newProductName: editedProductInfo.productName,
            });

            if (response.status === 200) {
                const updatedProductList = products.map((product) =>
                    product.productId === editedProductInfo.productId
                        ? editedProductInfo
                        : product
                );
                setProducts(updatedProductList);
                setEditMode(false);

                const productListKey = `productList_${userInfo.userId}`;
                localStorage.setItem(productListKey, JSON.stringify(updatedProductList));

                // Notifying other tabs about the update
                localStorage.setItem(`productList_${userInfo.userId}_updated`, Date.now());
            } else {
                console.error('Failed to update product');
            }
        } catch (error) {
            console.error('Error updating product:', error);
        } finally {
            setLoadingSaveChanges(false);
        }
    };


    const handleDeleteProduct = async (product) => {
        setLoading(true);
        try {
            const response = await axiosInstance.post('/api/product/delete', {
                userId: userInfo.userId,
                platform: product.platform,
                productId: product.productId,
            });

            console.log(response.data.data);

            if (response.data.data === true) {
                const updatedProductList = products.filter((p) => p.productId !== product.productId);
                setProducts(updatedProductList);
                setShowDetails(false);

                const productListKey = `productList_${userInfo.userId}`;
                localStorage.setItem(productListKey, JSON.stringify(updatedProductList));

                // Notifying other tabs about the deletion
                localStorage.setItem(`productList_${userInfo.userId}_updated`, Date.now());
            } else {
                console.error('Failed to delete product');
            }
        } catch (error) {
            console.error('Error deleting product:', error);
        } finally {
            setLoading(false);
        }
    };

    const redirectToAmazonProduct = (product) => {
        const productUrl = `https://amazon.in/dp/${product.productId}`;
        window.open(productUrl, '_blank');
    };

    const redirectToMyntraProduct = (product) => {
        const productUrl = `https://myntra.com/${product.productId}`;
        window.open(productUrl, '_blank');
    };

    const redirectToFlipkartProduct = (product) => {
        const productUrl = product.productUrl;
        if (productUrl != null) {
            window.open(productUrl, '_blank');
        }
        return;
    };

    return (
        <>
        <Header/>
        <div id="product-list-page">
            <div id="product-list-page-container">
                <div id="product-list-header-div">
                    <h1>{userInfo.firstName}'s Watchlist</h1>
                </div>
                <div id="product-list-data-container">
                    
                    <table className={`product-list-table ${loading ? 'loading' : ''}`}>
                        <thead className="product-list-thead-dark">
                            <tr>
                                <th>Product Name</th>
                                <th>Platform</th>
                                <th>Details</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading ? (
                                <tr>
                                    <td colSpan="3">
                                        <LoadingSpinner1 />
                                    </td>
                                </tr>
                            ) : products && products.length > 0 ? (
                                products.map((product) => (
                                    <tr key={product.productId}>
                                        <td>{product.productName}</td>
                                        <td className='product-list-image-column'>
                                            {product.platform === 'AMAZON' && (
                                                <img src={AmazonIcon} alt="Amazon" onClick={() => { redirectToAmazonProduct(product) }} />
                                            )}
                                            {product.platform === 'FLIPKART' && (
                                                <img src={FlipkartIcon} alt="Flipkart" onClick={() => { redirectToFlipkartProduct(product) }} />
                                            )}
                                            {product.platform === 'MYNTRA' && (
                                                <img src={MyntraIcon} alt="Myntra" onClick={() => { redirectToMyntraProduct(product) }} />
                                            )}
                                        </td>
                                        <td>
                                            <button
                                                className="product-list-details-button"
                                                onClick={() => handleDetailsClick(product)}
                                            >
                                                Details
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="3">No products available</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                    {products && products.length > 0 ?(<div id='product-list-product-number'>No of products: {products.length}</div>):(<></>)}
                </div>
            </div>

            <ProductDetailOverlay
                selectedProduct={selectedProduct}
                editedProductInfo={editedProductInfo}
                editMode={editMode}
                showDetails={showDetails}
                handleCloseModal={handleCloseModal}
                handleInputChange={handleInputChange}
                handleSaveChanges={handleSaveChanges}
                toggleEditMode={toggleEditMode}
                handleDeleteProduct={handleDeleteProduct}
                loadingSaveChanges={loadingSaveChanges}
            />
        </div>
        </>
    );
};

export default ProductList;
