import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './Navbar.css';
import NameLogo from '../../../resources/name-logo-2.png';
import menuIcon from '../../../resources/menu-icon.png';
import AuthContext from '../../../context/authContext/AuthContext';
import BackArrow from '../../../resources/back-arrow.png';

function Navbar({ isSidebarOpen, openSidebar, closeSidebar, handleLogout }) {
    const { userInfo, updateUserInfo } = useContext(AuthContext);
    const navigate = useNavigate();

    return (
        <div className="backbutton-navbar-main-div">
            <div className="backbutton-navbar-logo-div">
                <a href="/">
                    <img src={NameLogo} alt="Logo" />
                </a>
            </div>
            <div className='backbutton-navbar-back-button-div'>
                <button className='backbutton-navbar-back-button' onClick={()=>{navigate(-1)}}>
                    <img src={BackArrow}/>
                </button>
            </div>

            <div className="backbutton-navbar-links-main-div">
                <div className="backbutton-navbar-links-div"><a href="/">Home</a></div>
                <div className="backbutton-navbar-links-div"><a href="/addproduct">Add Product</a></div>
                <div className="backbutton-navbar-links-div"><a href="/watchlist">My Watchlist</a></div>
                <div className="backbutton-navbar-links-div"><a href="/userprofile">User Profile</a></div>
                <div className="backbutton-navbar-logout-div"><button onClick={handleLogout}>Logout</button></div>
            </div>

            <div className="backbutton-navbar-menu-icon-div">
                <img src={menuIcon} alt="Menu" onClick={isSidebarOpen ? closeSidebar : openSidebar} />
            </div>
        </div>
    );
}

export default Navbar;
