import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './ChangeUsername.css';
import useAxiosInstance from '../../../configs/useAxiosInstance';
import AuthContext from "../../../context/authContext/AuthContext";
import LoadingSpinner2 from "../../loadingAnimation/loading-spinner-2/LoadingSpinner2";
import BackButtonHeader from "../../backButtonHeader/BackButtonHeader";

function ChangeUsername() {

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [editedUserInfo, setEditedUserInfo] = useState({});
    const [usernameAvailability, setUsernameAvailability] = useState(null);
    const [showUsernameAvailability, setShowUsernameAvailability] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const navigate = useNavigate();

    const { userInfo, updateUserInfo } = useContext(AuthContext);
    const axiosInstance = useAxiosInstance();

    useEffect(() => {
        setEditedUserInfo({
            username: userInfo.username
        });
    }, [userInfo]);

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const userDetailDto = {
                firstName: userInfo.firstName,
                lastName: userInfo.lastName,
                mobileNumber: userInfo.mobileNumber,
                username: editedUserInfo.username || userInfo.username,
                email: userInfo.email
            };

            const response = await axiosInstance.post(`api/user/update/user-info`, userDetailDto);

            if (response.data.data === true) {
                setMessage('Username updated successfully!');
                updateUserInfo(
                    userInfo.userId,
                    userInfo.firstName,
                    userInfo.lastName,
                    userInfo.email,
                    editedUserInfo.username || userInfo.username,
                    userInfo.mobileNumber,
                    userInfo.isLoggedIn,
                    userInfo.accountCreatedAt,
                    userInfo.token
                );
                setShowUsernameAvailability(false);
            } else {
                setMessage('Failed to update username');
            }
        } catch (error) {
            console.error('Error updating username:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (newValue) => {
        setEditedUserInfo((prevInfo) => ({
            ...prevInfo,
            username: newValue,
        }));

        clearTimeout(window.usernameCheckTimeout);

        window.usernameCheckTimeout = setTimeout(() => {
            checkUsernameAvailability(newValue);
        }, 500);

        if (
            newValue !== userInfo.username
        ) {
            setHasChanges(true);
        } else {
            setHasChanges(false);
        }
    };

    const checkUsernameAvailability = async (username) => {

        if (username.trim() === '' || username.trim() == userInfo.username) {
            setShowUsernameAvailability(false);
            setUsernameAvailability(null);
            return;
        }

        setShowUsernameAvailability(true);
        try {
            const response = await axiosInstance.get(`api/register/check/username/${username}`);
            setUsernameAvailability(response.data);
        } catch (error) {
            console.error('Error checking username availability:', error);
        }
    };

    const isSaveButtonDisabled = loading || !hasChanges || !usernameAvailability ;

    return (
        <>
            <BackButtonHeader />
            <div id="change-username-page">
                <div className="change-username-page-container">
                    <form onSubmit={handleSaveChanges} id="change-username-form">
                        <label>Username
                            <input
                                type='text'
                                value={editedUserInfo.username || ''}
                                onChange={(e) => handleInputChange(e.target.value)}
                            />
                        </label>
                        {showUsernameAvailability && (
                            <div className="change-username-username-availability-status">
                                {usernameAvailability ? (
                                    <span style={{ color: 'green' }}>Username is available</span>
                                ) : (
                                    <span style={{ color: '#360c0c' }}>Username is already taken</span>
                                )}
                            </div>
                        )}
                        <button
                            type="submit"
                            disabled={isSaveButtonDisabled} // Disable when loading or no changes
                            className={`change-username-submit-button ${isSaveButtonDisabled ? 'change-username-submit-button-disabled' : 'change-username-submit-button-enabled'}`}
                        >
                            {loading ? <LoadingSpinner2 /> : 'Save'}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default ChangeUsername;
