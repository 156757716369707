import './LoadingSpinner1.css'

function LoadingSpinner1() {
    return (
        <div className='loading-spinner-1-container'>
          <div className='loading-spinner-1'></div>
        </div>
    );
}

export default LoadingSpinner1;