import React, { useEffect, useState } from 'react';
import './Popup.css';

const Popup = ({ message, onClose, handleClickOutside }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);
  }, []);

  function handleOutsideClick(e) {
    const popupBox = document.querySelector('.popup-box');
    if (popupBox && !popupBox.contains(e.target)) {
      handleClickOutside();
    }
  }

  const handleClose = () => {
    setVisible(false);
    setTimeout(() => {
      onClose();
    }, 500);
  };

  return (
    <>
      <div className={`popup-blur-background ${visible ? 'show' : ''}`}></div>
      <div className={`popup-overlay ${visible ? 'show' : ''}`} onClick={handleOutsideClick}>
        <div className={`popup-box ${visible ? 'show' : ''}`}>
        <p className="popup-message" dangerouslySetInnerHTML={{ __html: message }} />
          <div className='popup-close-button-div'>
            <button onClick={handleClose}>Ok</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
